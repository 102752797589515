.sectionContent {
  background: #f8f8f8;
  /* padding: 125px 50px 125px 255px; */
  /* padding: 5px 0 0 200px; */
  /* padding: 0 0 0 200px; */
  padding: 0 30px 0 370px;
  min-height: 100%;
  position: fixed;
  top: 0;
  width: 100vw;
  max-height: 100%;
  overflow: auto;
  transition: ease-in-out .1s;

  &.deactive-sub-menu {
    padding: 0 30px 0 92px;
  }

  &.section-wrap-active-billing-warning {
    top: 32px;
    min-height: calc(100% - 32px);
    max-height: calc(100% - 32px);
  }
}

.sectionWrap {
  position: relative;
  background: #fff;
  padding: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 24px 0;
}

.sectionWrap .topContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionWrap .topContent.bordered {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sectionWrap .topContent .title {
  font-size: 20px;
  font-family: PTRootBold;
  line-height: 1.2;
  letter-spacing: -0.5px;
}

.sectionWrap .bodyContent {
  margin: 4px auto 24px;
  position: relative;
}

.sectionWrap .formBodyContent {
  margin: 24px auto;
}

.sectionWrap .formBodyContent label {
  margin: 0 0 8px;
}

.sectionWrap .bodyContent .reload {
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 35px;
}

.sectionWrap .flexContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionWrap .flexContent.left {
  justify-content: flex-start;
}

.sectionWrap .flexContent .agentSearch {
  max-width: 370px;
  flex: 1;
  padding-right: 20px;
}

.sectionWrap .flexContent .agentAction {
  display: flex;
  align-items: center;
  position: relative;
  top: -11px;
}

.sectionWrap .checkArea {
  display: flex;
  margin: 24px;
}

.sectionWrap .input-wrapper .input-icon {
  position: absolute;
  right: 15px;
  top: 14px;
  text-align: center;
  font-size: 16px;
  height: 24px;
  width: 24px;
  opacity: 0.5;
}

.sectionWrap .checkArea .input-wrapper {
  margin-right: 10px;
}

.sectionWrap .value {
  font-family: PTRootBold;
  text-transform: capitalize;
  margin: 4px 0;
  cursor: pointer;
}

.sectionWrap .formContent {
  position: relative;
  max-width: 350px;
}

.sectionWrap .tableContent {
  position: relative;
  margin: 4px auto 24px;
}

.sectionWrap .tcHeader,
.sectionWrap .tcRow {
  display: flex;
  width: 100%;
}

.sectionWrap .tcHeader {
  font-family: PTRootBold;
  font-weight: 600;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sectionWrap .tcRow {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.sectionWrap .tcRow.inside {
  border: none;
  padding: 0;
}

.sectionWrap .tcRow.plain {
  border: none;
}

.sectionWrap .tcCol {
  flex: 1;
  padding: 20px 10px;
  word-wrap: break-word;
}

.sectionWrap .tcHeader .tcColCheck {
  padding: 0;
  text-align: center;
}

.sectionWrap .tcRow .tcColCheck {
  padding: 14px 20px;
  text-align: center;
}

.sectionWrap .tcCol.capital {
  text-transform: capitalize;
}

.sectionWrap .tcHeader .tcCol {
  padding: 8px 10px;
}

.sectionWrap td.tcCol {
  flex: none;
}

.sectionWrap .tcCol.info {
  font-family: PTRootBold;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  padding: 0;
  margin-top: 32px;
  margin-bottom: 0;
}

.sectionWrap .tcCol.info.center {
  text-align: center;
}

.sectionWrap .tcRow.inside .tcCol {
  padding: 0;
}

.sectionWrap .tcCol.flex {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
}

@media (max-width: 1200px) {
  .sectionWrap .tcCol.flex {
    display: block;
  }
}

.sectionWrap .textInfo {
  font: 16px PTRootBold;
  line-height: 1.5;
  letter-spacing: -0.2px;
}

.sectionWrap .textInfo.center {
  text-align: center;
}

.sectionWrap .textInfo.grey {
  color: rgba(0, 0, 0, 0.4) !important;
}

.sectionWrap .textInfo.red {
  color: var(--watermelon);
}

.sectionWrap .textInfo.emp {
  font-weight: 600;
  font-style: italic;
}

.Modal .modal-content {
  position: relative;
  padding: 32px;
}

.Modal .popup-header {
  font-size: 20px;
  font-family: PTRootBold;
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.Modal .popup-body {
  position: relative;
}

.Modal .popup-body .errorInfo {
  color: var(--watermelon);
  font-style: italic;
  font-size: 14px;
  margin: 8px;
}

.Modal .popup-desc {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: -0.2px;
  line-height: 1.5;
  font-size: 14px;
}

.Modal .button-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.Modal .button-flex button {
  display: inline-block;
  flex: 1;
}

.inputCopy {
  position: relative;
}

.inputCopy .iconCopy {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: #fff;
  position: absolute;
  top: 5px;
  right: 6px;
  /* transform: translate(0, -50%); */
  width: 40px;
  height: 40px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}

.inputCopy input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 13px 40px 15px 15px;
}

.fontBold {
  font-family: PTRootBold;
}
