.low-balance-main-wrapper {
  .low-balance-header-title {
    padding-bottom: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919;
    width: 100%;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);

    &.single-title {
      margin-bottom: 24px;
    }
  }

  .form-content-wrapper {
    padding-top: 24px;

    .alert-low-balance {
      font-size: 12px;
      letter-spacing: -0.4px;
      color: rgba(25, 25, 25, 0.6);
      width: 322px;
      margin-top: 22px;
      position: relative;
      padding-left: 18px;

      svg {
        font-size: 14px;
        position: absolute;
        left: 0;
        top: 2px;
        margin-right: 4px;
      }
    }

    .alert-component {
      border: solid 1px #0C5F99;
      background: rgba(235, 247, 255, 1);
      color: #0C5F99;
      display: inline-block;
      margin: 24px 0;
    }

    label {
      font-size: 16px;
      letter-spacing: -0.5px;
      margin: 0 0 4px 0;
    }

    input {
      margin-top: 4px;
      padding-left: 36px;
    }

    .value-wrapper {
      position: relative;
      
      .custom-switch-wrapper {
        display: inline-block !important;
        width: 40px;
        height: 24px;
        position: absolute;
        top: -26px;
        right: 0;
        margin: 0;

        .custom-switch.round {
            &::before {
                width: 14px;
                height: 14px;
                bottom: 5px;
                left: 4px;
            }
        }

        input:checked + .custom-switch:before {
            transform: translateX(18px);
        }
      }

      .span-rp {
        position: absolute;
        top: 20px;
        left: 16px;
        line-height: 1;
      }
    }
  }

  button {
    width: unset;
    margin: 0 8px 0 0;
  }
}
  
  