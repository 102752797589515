.whatsapp-create-verification-container {
    .whatsapp-create-verification-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;

        button {
            width: 180px;
        }

        span {
            font-family: PTRootUI;
            display: block;
            font-size: 14px;
            letter-spacing: -0.2px;
            margin-top: 4px;

            a {
                color: var(--main-dark-orange);
    
                &:hover {
                    color: var(--main-darker-orange) !important;
                }
            }
        }
    }

    .whatsapp-create-verification-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        .whatsapp-create-verification-title {
            margin: 24px 0 8px;
            font-size: 16px;
            letter-spacing: -0.5px;

            span {
                color: rgba(25, 25, 25, 0.6);
            }
        }

        input[type="text"] {
            width: 344px;
            border-radius: 8px;
            border: solid 1px rgba(25, 25, 25, 0.4);
            padding: 12px 16px;
        }

        .input-wrapper {
            padding: 0;
            position: relative;

            &.input-minute {
                width: 344px;
                display: inline-block;

                input {
                    width: 163px;
                }

                span {
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    position: absolute;
                    top: 21px;
                    left: 172px;
                }
            }

            &.input-phone {
                input {
                    padding-left: 30px;
                }
                
                svg {
                    position: absolute;
                    left: 16px;
                    font-size: 14px;
                    top: 18px;
                }
            }
        }

        .select-lang {
            width: 344px;
        }

        .submit-button {
            width: 180px;
            margin-top: 24px;
        }
    }

    .alert-box {
        margin-top: 24px;
        color: #ff7e00;
        border: solid 1px #ff7e00;
        background: #FFF4B1;
    }
}

.modal-submit-verification {
    .modal-content {
        padding: 32px;
        border-radius: 8px;
        overflow: auto;
        max-height: calc(100vh - 50px);
        width: 480px;

        .modal-body {
            padding: 0;
            
            p {
                display: block;
                font-size: 20px;
                letter-spacing: -0.6px;

                svg {
                    position: relative;
                    top: -1px;
                    margin-right: 4px;
                }
            }

            label {
                font-size: 16px;
                letter-spacing: -0.5px;
                display: block;
            }

            button {
                width: 100%;
                margin-top: 20px;
            }
        }

        .copy-box-wrapper {
            margin-bottom: 24px;

            input {
                color: rgba(25, 25, 25, 0.6);
                background: rgba(25, 25, 25, 0.05);
            }

            textarea {
                color: rgba(25, 25, 25, 0.6);
                background: rgba(25, 25, 25, 0.05);
            }
        }

        .a-link-href {
            width: 100%;
            display: block;
            color: var(--main-dark-orange);
            text-align: center;
            cursor: pointer;

            &:hover {
                color: var(--main-darker-orange);
            }

            svg {
                position: relative;
                top: -2px;
                margin-left: 4px;
            }
        }
    }
}