.LoadingRoll_1234fed {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 0;
  color: transparent;
  height: 40px;
  padding: 0 10px;
  max-width: 100%;
  max-height: 100%;
}

.LoadingRoll_1234fed img {
  max-width: 100%;
  max-height: 100%;
}

.white-lds-ring div {
  border-color: #ffffff transparent transparent transparent;
}

.gray-lds-ring div {
  border-color: gray transparent transparent transparent;
}
