.form-content-wrapper label {
    display: block;
    color: #191919;
    font-size: 14px;
    margin-top: 24px;
  
    &.label-disabled {
      color: rgba(25, 25, 25, 0.2);
    }
  
    .input-label-optional {
      color: rgba(25, 25, 25, 0.6);
    }
  }
  
  .form-content-wrapper input[type="text"] {
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    height: 48px;
    width: 348px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 316px;
    max-width: 100%;
    padding: 0 16px;
  }
  
  .form-content-wrapper textarea {
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid rgba(25, 25, 25, 0.1);
    min-height: 48px;
    width: 348px;
    color: #191919;
    font-size: 16px;
    letter-spacing: -0.3px;
    width: 316px;
    max-width: 100%;
    padding: 12px 16px;
    line-height: 24px;
    resize: none;
  }
  
  .form-content-wrapper label {
    display: block !important;
    letter-spacing: -0.4px;
  }
  
  .form-content-wrapper .role-radio-label {
    position: relative;
  }
  
  .form-content-wrapper .role-radio-label b {
    display: block;
    color: #191919;
    font-size: 14px;
    letter-spacing: -0.4px;
  } 
  
  .form-content-wrapper .role-radio-label label {
    color: rgba(25, 25, 25, 0.8);
    font-size: 14px;
    letter-spacing: -0.2px;
  } 
  
  .form-content-wrapper .custom-radio label {
    margin-top: 18px;
  }
  
  .form-content-wrapper button {
    width: 300px;
    padding: 10px 0 11px;
    margin-top: 32px;
  }
  
  .form-content-wrapper .orange-button .lds-ring {
    top: 3px;
    right: unset;
  }
  
  .form-content-wrapper .orange-button .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
    margin: 0;
  }
  
  .form-content-wrapper.disabled-field label {
    color: rgba(25, 25, 25, 0.2) !important;
  }
  
  .form-content-wrapper.disabled-field input[type="text"] {
    color: rgba(25, 25, 25, 0.4);
    background: #E8E8E8;
    cursor: not-allowed;
  }
  .form-content-wrapper input[type="text"]:focus, .form-content-wrapper textarea:focus {
    border: solid 1px #ff7e00 !important;
  }