.whatsapp-verification-container {
    .whatsapp-verification-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;

        button {
            width: 180px;
        }
    }

    .whatsapp-verification-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        .copy-box-wrapper {
            svg {
                top: 17px !important;
            }
        }

        .whatsapp-verification-title {
            margin: 24px 0 8px;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .whatsapp-verification-value {
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            max-width: 100%;
            width: 400px;

            b {

                img {
                    position: relative;
                    top: -1px;
                    margin-right: 2px;
                }
            }
        }
    }
}