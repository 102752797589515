.whatsapp-verification-history-detail-container {
    .whatsapp-verification-history-detail-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;

        button {
            width: 180px;
        }
    }

    .whatsapp-verification-history-detail-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        .whatsapp-verification-history-detail-title {
            margin: 24px 0 4px;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .whatsapp-verification-history-detail-value {
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            max-width: 100%;
            width: 400px;

            b {

                img {
                    position: relative;
                    top: -1px;
                    margin-right: 2px;
                }
            }
        }

        .history-value {
            line-height: 1;
            font-size: 16px;
            letter-spacing: -0.3px;
        }
    }

    .copy-box-wrapper {
        margin-bottom: 24px;

        input {
            color: rgba(25, 25, 25, 0.6);
            background: rgba(25, 25, 25, 0.05);
        }

        textarea {
            color: rgba(25, 25, 25, 0.6);
            background: rgba(25, 25, 25, 0.05);
        }
    }
}