/*table with checkbox*/
.custom-table {
    position: relative;
  }
  
  .custom-table-content {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table-content tr td {
    padding: 18px 10px;
    position: relative;
    white-space: nowrap;
  }
  
  .custom-table-content tr td > .custom-checkbox {
    display: inline-block;
    position: relative;
    top: -7px;
    margin-right: 15px;
  }
  
  .custom-table-content thead tr {
    background: #f8f8f8;
  }
  
  .custom-table-content thead tr td {
    padding: 10px;
    font-size: 14px;
    letter-spacing: -0.4px;
  }
  
  .custom-table-content tbody tr:hover td {
    background: rgba(255, 126, 0, 0.15);
  }

  .custom-table-content thead tr {
    border-bottom: solid 1px #dcdcdc;
    background: #ffffff;
  }
  
  .custom-table-content tr {
    // border-bottom: solid 1px #dcdcdc;
    background: #ffffff;
  }

  .custom-table-content tr.no-data-row {
    border-bottom: unset;
  }
  
  .custom-table-content tr:nth-child(even) {
    background: #f8f8f8;
  }
  
  .custom-table-content thead {
    font-weight: bold;
  }
  
  .custom-table-content tbody tr td {
    /* cursor: pointer; */
    font-weight: 500;
    position: relative;
    max-width: 300px;
    white-space: normal;
    vertical-align: top;
    font-size: 14px;
    letter-spacing: -0.2px;
    color: rgba(25, 25, 25, 0.8)
  }
  
  .custom-table-content tbody tr td.no-data-column {
    text-align: center;
    color: #a6a6a6;
  }
  
  .custom-table-content tbody tr td.action {
    width: 50px;
  }
  
  .custom-table-content tbody tr td svg {
    font-size: 22px;
    color: var(--main-dark-orange);
    position: absolute;
    right: 10px;
    top: 18px;
  }
  
  .custom-table-content tbody tr td.email div {
    display: inline-block;
    position: relative;
    top: -7px;
    margin-right: 16px;
  }
  
  .custom-table-content tbody tr td.col_action {
    width: 50px;
  }
  
  .custom-table-content tbody tr td.col_action div {
    display: none;
  }
  
  .custom-table-content tbody tr:hover td.col_action div {
    display: block;
  }
  
  .custom-table-content .resend-invitation {
    padding: 3px 25px;
  }
  /*table with checkbox*/