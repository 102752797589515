.outer-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: linear-gradient(135deg, #FFAB3A 0%, #FF7E00 100%);

  .background-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    bottom: 0;
    background: url('../../../assets/img/auth/illustration-auth.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

    .header-container {
      margin: 32px 0 36px;
      text-align: center;
  
      img {
        margin-bottom: 12px;
      }
  
      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
  
    .form-container {
      position: relative;
      width: 500px;
      background: #FFFFFF;
      border-radius: 12px;
      z-index: 1;
      padding: 48px;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

      .fail-panel {
        width: 100%;
        background: #FFE6E9;;
        border-radius: 8px;
        color: #ff3f57;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 12px 15px 10px;
        margin-bottom: 24px;
        text-align: left;
        border: 1px solid #FF3F57;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        display: flex;

        .icon-wrapper {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          position: relative;
          top: -2px;

          svg {
            font-size: 24px;
          }
        }
      }
  
      .form-title {
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: -0.6px;
        color: #191919;
        margin-bottom: 32px;
      }
  
      .input-wrapper {
        position: relative;
        padding-bottom: 24px;
  
        .popup-input-password-wrapper {
          .peek-password {
            top: 5px;
          }
  
          .active-eye {
            color: #ff7d00;
          }
        }
        
        label {
          font-size: 14px;
          letter-spacing: -0.4px;
          color: #191919;
          margin: 0;
        }
  
        input {
          margin: 8px 0 0;
          display: block;
          border-radius: 8px;
          border: solid 1px #dcdcdc;
          padding: 13px 15px 15px;
          font-size: 16px;
          width: 100%;
          box-sizing: border-box;
          letter-spacing: -0.2px;
  
          &.fail-state-input {
            border: solid 1px #ff3f57;
          }
  
          &::placeholder {
            color: #9b9b9b;
          }
  
          &:focus {
            border: solid 1px var(--main-dark-orange);
          }
        }
  
        .input-error {
          margin: 6px 12px;
          font-size: 13px;
          letter-spacing: 0.5px;
          font-weight: 600;
          color: rgba(255, 0, 0, 0.7);
        }
  
        .password-title-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
  
          .forgot-password {
            color: #ff7d00;
            cursor: pointer;
          }
        }
      }
  
      .signup-here {
        font-size: 16px;
        letter-spacing: -0.2px;
        color: #191919;
        text-align: center;
        margin-top: 32px;
        color: rgba(25, 25, 25, 0.5);
  
        .signup-link {
          margin-left: 4px;
          color: #ff7e00;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
}

.modal-login {
  img {
    width: 30px;
    position: relative;
    top: -2px;
  }

  .modal-content {
    padding: 30px 40px;
    border-radius: 8px;
    width: 540px;
  }

  .modal-body {
    padding: 0;

    p {
      font-size: 16px;
      font-weight: normal;
      color: #191919;
      letter-spacing: -0.3px;
      text-align: center;
    }

    svg {
      font-size: 26px;
      position: relative;
      top: -2px;
    }

    .title {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -0.4px;
      color: #52d540;

      svg {
        margin-right: 8px;
      }

      &.not-verified {
        color: rgba(25, 25, 25, 0.4);
      }
    }
  }

  .modal-footer {
    padding: 0 0 10px;
    border: none;

    button {
      // padding: 10px 0 12px;
      width: 100%;
      position: relative;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 4px;
        font-size: 20px;
      }

      b {
        font-size: 16px;
      }
    }
  }

  .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
  }
}

// @function vh($quantity) {
//   @return calc(var(--vh, 1vh) * #{$quantity});
// }

@media screen and (max-width: 1025px) {
  .outer-container .background-container {
    background: url('../../../assets/img/auth/illustration-auth-tablet@2x.png') !important;
    // background-size: 386.5px 378px !important;
    background-size: 50% !important;
    background-repeat: no-repeat !important;
    background-position: left bottom !important;
    height: 100%;
    // height: 100vh; // fallback
    // height: vh(100);
    // height: -webkit-fill-available
  }
  .modal-login {
    .modal-content {
      padding: 32px 24px;
      border-radius: 12px;
      width: 476px;
    }
    .modal-body {
      .title {
        font-size: 20px;
        letter-spacing: -0.6px;
  
        svg {
          font-size: 20px;
        }
      }
      p {
        font-size: 14px;
        font-weight: normal;
        line-height: 21px;
        letter-spacing: -0.2px;
      }
    }
  }
  .modal-dialog {
    width: 100vw !important;
  }
}

@media screen and (max-width: 480px) {
  .outer-container {
    .background-container {
      background: unset !important;
    }
    .form-container {
      width: 343px !important;
      padding: 28px !important;
    }
    .form-title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
  } 
  .modal-login {
    .modal-content {
      width: 343px;
    }
  }
  .modal-dialog {
    width: 100vw !important;
  }
}