.main-button-40 {
    padding: 0 32px !important;
    height: 40px;
    line-height: 37px;
    vertical-align: top;
    font-size: 16px !important;

    svg {
        position: relative;
        top: -1.5px;
        margin-right: 6px;

        &.icon-right {
            margin-left: 6px;
            margin-right: 0;
        }
    }

    img {
        position: relative;
        top: -1.5px;
        width: 16px;
        margin-right: 6px;

        &.icon-right {
            margin-left: 6px;
            margin-right: 0;
        }
    }

    &.width-100 {
        width: 100%;
    }
}

.main-button-48 {
    padding: 0 40px !important;
    height: 48px;
    line-height: 45px;
    vertical-align: top;

    svg {
        position: relative;
        top: -1.5px;
        margin-right: 6px;
        font-size: 20px;

        &.icon-right {
            margin-left: 6px;
            margin-right: 0;
        }
    }

    img {
        position: relative;
        top: -1.5px;
        width: 16px;
        margin-right: 6px;

        &.icon-right {
            margin-left: 6px;
            margin-right: 0;
        }
    }

    &.width-100 {
        width: 100%;
    }
    
    &[disabled] {
        line-height: 46px;
    }
}