.ToastContainer {
  width: auto !important;
  font-size: 16px;
}

.Toastify__toast {
  min-height: 0;
  max-width: 300px;
  margin-bottom: 0;
  box-shadow: none;
  font-size: 14px;
  // padding: 3px 16px 3px 34px;
  padding: 8px;
  word-break: break-word;
  min-height: unset !important;

  svg {
    position: absolute;
    top: 11px !important;
    left: 6px !important;
  }
}

.ToastContainer .ToastContent-no-icon {
  padding: 3px 16px;
  background: #191919;
  color: #fff !important;
  border-radius: 8px !important;
  width: auto !important;
}

.Toastify__toast-container {
  padding: 0;
  margin-bottom: 0;
}

.Toastify__toast-body {
  margin: 0 !important;
  padding: 0 0 0 18px;
}

.ToastContainer .ToastContent {
  /* background: #7EC82A !important; */
  background: #191919;
  color: #fff !important;
  border-radius: 8px !important;
  width: auto !important;
}

.ToastContainer svg {
  position: absolute;
  top: 11px;
  left: 16px;
}

.ToastContainer .ToastContent-fail {
  background: #ff3f57 !important;
  color: #fff !important;
  border-radius: 8px !important;
  // padding: 3px 16px !important;
  width: auto !important;
}

.ToastContainer .ToastContent button, .ToastContainer .ToastContent-fail button, .ToastContainer .ToastContent-no-icon button {
  display: none;
}

.ToastContainer .ToastContent .Toastify__progress-bar {
  background: #fff !important;
}