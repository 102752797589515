.case-modal-calendar {
    width: fit-content !important;
}

.case-modal-calendar .modal-body {
    padding: 1.5rem 1rem 1rem;
}

.date-filter-wrapper {
    text-align: center;
}

.modal-date-filter-title {
    position: relative;
    padding: 0 15px;
}

.modal-date-filter-title svg {
    position: absolute;
    top: 2px;
    font-size: 18px;
}

.modal-date-filter-title b {
    padding: 0 25px;
}

.case-modal-calendar {
    .modal-content {
        position:  relative !important;
    }

    .modal-footer {
        border-top: unset !important;
        padding: .75rem .75rem 1rem !important;
    }

    .modal-footer > * {
        margin: .25rem;
    }
    
    .modal-footer button {
        padding: 5px 20px;
    }
    
    .modal-footer button svg {
        font-size: 23px;
    }
}