.message-history-main-wrapper {
  .header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;

    .header-wrapper {
      .message-history-header-title {
        padding-bottom: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        color: #191919;
        width: 100%;
    
        &.single-title {
          border-bottom: 1px solid rgba(25, 25, 25, 0.1);
          margin-bottom: 24px;
        }
      }
    
      .message-history-header-description {
        font-size: 16px;
        letter-spacing: -0.3px;
        color: rgba(25, 25, 25, 0.8);
        line-height: 1.5;
        padding: 0 0 18px 0;
        width: 100%;
      }
    }
  }

  .button-container {
    margin-bottom: 24px;
    display: flex;
    
    .date-button {
      margin-right: 4px;
    }

    .filter-button {
      display: flex;
      align-items: center;

      .badge-counter {
        font-size: 12px;
        margin-left: 4px;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        color: #FF7E00;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

}

.table-message-history-list {
  table-layout: fixed;

  thead {
    tr {
      background: transparent;
  
      td {
        padding: 8px;
        width: 22%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
  
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(6) {
          width: 12%;
        }

        &:nth-child(5) {
          width: 20%;
        }
  
        &:last-child {
          width: 90px;
  
          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;

            p {
              font-size: 12px;
              font-weight: bold;
            }
  
            .custom-select-option-value {
              padding: 0;
            }
  
            svg {
              right: 3px;
              height: 15px;
              top: 11px;
            }
          }
        }
  
      }
    }
  }

  tbody {
    .loading-container {
      width: 100%;
    }

    tr {
      // cursor: pointer;

      &:hover {
        background-color: unset;

        td {
          color: unset;
        }
      }

      td:not(.no-data-column) {
        padding: 8px 10px;
        p {
          margin-bottom: 0;
        }
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(1) {
          p {
            margin-bottom: 4px;
          }

          .col_name_alias {
            font-size: 12px;
          }
        }

        &:nth-child(4) {
          &.col-origin {
            p {
              margin: 0;
              display: inline;
            }

            img {
              position: relative;
              top: -2px;
              margin-right: 8px;  
            }
          }
        }

        &.col-view {
          color: #FF7E00;
          cursor: pointer;
          font-weight: 700;

          svg {
            position: relative;  
            color: #FF7E00;
            font-size: 16px;
            margin-right: 4px;
            right: 0;
            top: -2px;
          }
        }

        &.col-status {
          p {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.table-message-history-list tbody tr {
  &:nth-child(even) {
    background: rgba(25, 25, 25, 0.05);
  }
}

.table-message-history-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
}

.table-message-history-list td {
  max-width: unset !important;
}

.modal-view-message {
  .modal-content {
    padding: 32px;
    width: 477px;
  }

  .modal-body {
    padding: 0;

    .header-modal {
      margin-bottom: 24px;

      b {
        font-size: 20px;
      }

      svg {
        margin-right: 4px;
        position: relative;
        top: -3px;
      }
    }

    .message-container {
      label {
        font-size: 14px;
      }

      input {
        display: flex;
        align-items: center;
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        height: 48px;
        width: 100%;
        color: rgba(25, 25, 25, 0.6);
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 0 16px;
        margin-bottom: 24px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }
        
      textarea {
        background: white;
        border-radius: 8px;
        border: 1px solid rgba(25, 25, 25, 0.1);
        min-height: 48px;
        width: 100%;
        color: rgba(25, 25, 25, 0.6);
        font-size: 16px;
        letter-spacing: -0.3px;
        max-width: 100%;
        padding: 12px 16px;
        line-height: 24px;
        resize: none;
        margin-bottom: 20px;

        &:disabled {
          background: rgba(25, 25, 25, 0.05);
          cursor: not-allowed;
        }
      }
    }
  }
}