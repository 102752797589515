.check-option-button-wrapper {
  padding: 10px 14px;
  background: #fff;
  border: 1px solid rgba(25, 25, 25, 0.1);
  border-radius: 8px;
  cursor: pointer;
  width: 30%;
//   width: fit-content;

  label {
    margin: 0 !important;
    img {
        width: 28px;
        height: 28px;
        vertical-align: -7px;
        margin-right: 6px;
      }
    p {
      display: inline-block;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
      color: #191919;
      margin-bottom: 0;
      vertical-align: 2px;
    }
  }

  label:before {
    margin-right: 10px;
    width: 15.43px;
    height: 15.43px;
    top: 5px !important;
  }
  .custom-checkbox [type="checkbox"]:checked + label:after {
      top: -1px !important;
  }
}

.check-option-button-wrapper.selected, .check-option-button-wrapper:hover {
  background: #fff2e5;
  border: 1px solid #ff7e00;
}
