#outer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* background: var(--main-dark-orange); */
  background: linear-gradient(to bottom, #ffab3a, #ff7d00);
  overflow-y: auto;
}

.wrapper-full-left {
  position: fixed;
  top: 0;
  left: 0;
  border-top-right-radius: 40px;
  width: 540px;
  height: 100%;
  background: #ffffff;
  text-align: left;
}

.wrapper-full-right-image {
  position: fixed;
  right: 0;
  top: 0;
  width: calc(100% - 540px);
  height: 100vh;
}

.header-centered-content {
  text-align: center;
}

.subtitle {
  margin: 12px 0 32px;
  letter-spacing: 2.5px;
  color: rgba(25, 25, 25, 0.8);
  font-size: 12px;
}

.content-wrapper {
  padding: 48px;
  height: 100%;
}

.content-wrapper img.logo-image {
  width: 154px;
  display: inline-block;
}

.content-title {
  font-weight: bold;
  font-size: 24px;
  color: #191919;
  margin: 0 0 24px;
  letter-spacing: -0.4px;
  display: block;
}

.fail-panel {
  width: 100%;
  background: #ffc0c0;
  border-radius: 8px;
  color: #ff3f57;
  font-size: 16px;
  letter-spacing: -0.5px;
  padding: 12px 15px 10px;
  margin-bottom: 24px;
  text-align: left;
}

.fail-panel img {
  position: relative;
  top: -2px;
  margin-right: 8px;
}

.input-wrapper {
  position: relative;
  padding-bottom: 24px;
}

.input-wrapper label {
  font-size: 14px;
  /* font-weight: bold; */
  letter-spacing: -0.4px;
  color: #191919;
  margin: 0;
}

.input-wrapper input {
  margin: 8px 0 0;
  display: block;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  padding: 13px 15px 15px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  letter-spacing: -0.2px;
}

.input-wrapper input.fail-state-input {
  border: solid 1px #ff3f57;
}

.input-wrapper input::placeholder {
  color: #9b9b9b;
}

.input-wrapper input:focus {
  border: solid 1px var(--main-dark-orange);
  /* box-shadow: 0 0 8px 0 rgba(54, 42, 215, 0.24); */
}

.input-wrapper #forgot-password {
  position: absolute;
  right: 0;
  top: 0;
}

.input-wrapper .input-error {
  margin: 6px 12px;
  font-size: 13px;
  /* font-style: italic; */
  letter-spacing: 0.5px;
  font-weight: 600;
  color: rgb(255, 0, 0, 0.7);
}

.input-warning {
  position: absolute;
  left: 0;
}

.login-button {
  width: 100%;
  margin-top: 10px;
  /* padding: 10px 0 12px; */
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: solid 1px #ff7e00;
  background: linear-gradient(to bottom, #ff9833, #ff7e00 100%);
  cursor: pointer;
  position: relative;
  /* height: 48px; */
}

.login-button .lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  top: 0;
  left: 0;
  margin: 0;
}

.login-button:hover {
  background: linear-gradient(to bottom, #ff9833, #ef7600 100%);
}

.login-button:disabled {
  /* background: linear-gradient(to bottom, #cecece, #9b9b9b 100%); */
  cursor: not-allowed;
  border: solid 1px #cecece;
}

#signup-here {
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #191919;
  text-align: center;
  margin-top: 32px;
  color: rgba(25, 25, 25, 0.5);
}

@media screen and (min-width: 300px) and (max-width: 550px) {
  .wrapper-full-left {
    width: 100%;
  }

  .wrapper-full-left .content-wrapper {
    padding: 32px 28px;
  }

  .wrapper-full-left .content-wrapper .content-title {
    font-size: 30px;
    margin: 21px 0;
  }

  .wrapper-full-left .content-wrapper .input-wrapper label,
  span {
    font-size: 15px;
  }

  .wrapper-full-left .content-wrapper .login-button {
    margin: 16px 0px !important;
    padding: 11px 0 12px;
    font-size: 15px;
  }

  .wrapper-full-left .content-wrapper .input-wrapper input[type="text"],
  input[type="password"] {
    padding: 9px 11px 11px;
    font-size: 14px;
  }

  .wrapper-full-left .content-wrapper #signup-here {
    font-size: 15px;
  }
}
