.img-overlay {
  color: white;
  position: relative;
  width: fit-content;
  cursor: pointer;
  img {
    display: block;
  }
  img,
  svg {
    border-radius: 8px;
    width: 56px;
    height: 56px;
    object-fit: cover;
    margin: 8px 0;
  }
  .eye-icon {
    display: none;
    svg {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -12px;
      margin-top: -12px;
    }
  }
  .x-button {
    background-color: #ff3f57;
    color: #fff;
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: -8px;
    right: -7px;
    border-radius: 50%;
    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -6px;
      right: 2px;
    }
  }
  .LoadingRoll_1234fed {
    position: absolute;
    height: 28px;
    left: 50%;
    margin-left: -24px;
    .lds-ring div {
      border: 5px solid #fff;
      width: 46px;
      height: 46px;
      border-color: #ffffff transparent transparent transparent;
    }
  }
}
.img-overlay::before,
.loading-img-overlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 8px;
  transition: 0.5s ease;
}
.img-overlay::before {
  opacity: 0;
}
.loading-img-overlay::before,
.img-overlay:hover::before {
  opacity: 0.2;
}
