.topup-confirmation-modal {
  .topup-confirmation-modal-amount-wrapper {
    font-size: 16px;
    letter-spacing: -0.5px;
    color: #191919;
    padding: 8px 16px;
    border-radius: 8px;
    background: #f3f3f3;
    margin: 16px 0 4px;
  }
}

.topup-now-main-wrapper {
  .button-topup-now  {
    font-size: 14px;
    letter-spacing: -0.5px;
    border-radius: 6px;
    padding: 1px 12px;
  }

  .top-up-now-amount-wrapper {
    width: 350px;

    label {
      font-size: 14px;
      letter-spacing: -0.4px;
    }

    .topup-amount-input-wrapper {
      position: relative;
      font-size: 16px;
      letter-spacing: -0.3px;

      span {
        position: absolute;
        left: 16px;
        top: 14px;
      }
    }

    input {
      font-size: 16px;
      letter-spacing: -0.3px;
      width: 100%;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      padding: 12px 16px 12px 42px;
      margin: 0 0 8px 0;

      &::placeholder {
        color: rgba(25, 25, 25, 0.4);
      }
    }

    .button-topupnow {
      margin-top: 12px;
    }

    .topup-info-text {
      font-size: 12px;
      letter-spacing: -0.4px;
      position: relative;
      padding-left: 16px;
      
      svg {
        position: absolute;
        left: 0;
        top: 3px;;
      }
    }
  }

  .select-topup-nominal-wrapper {
    width: 100%;
    position: relative;
    display: block;

    .select-topup-content {
      font-size: 14px;
      letter-spacing: -0.2px;
      cursor: pointer;
      border-radius: 8px;
      border: solid 1px #dcdcdc;
      display: inline-block;
      margin: 0 8px 8px 0;
      padding: 12px 10px;
      line-height: 1;

      &:hover {
        border: solid 1px #FF7E00;
      }

      &.select-topup-content-active {
        border: solid 1px #FF7E00;
      }
    }
  }

  .topup-now-header-title {
    padding-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919;
    width: 100%;

    &.single-title {
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
      margin-bottom: 24px;
    }
  }

  .topup-now-header-description {
    font-size: 16px;
    letter-spacing: -0.3px;
    color: rgba(25, 25, 25, 0.8);
    line-height: 1.5;
    padding: 0 0 12px 0;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    margin-bottom: 24px;
    width: 100%;
  }

  .topup-now-content-wrapper {
    .step-container {
      display: flex;
      width: 100%;
      margin-bottom: 24px;

      p {
        margin: 0;
        width: 100%;

        a {
          color: #FF7E00;
          font-weight: bold;
          text-decoration: underline !important;
        }
      }

      .step-number {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FF7E00;
        margin-right: 8px;
        position: relative;
        top: 2px;
        font-size: 12px;
        color: #ffffff;
        font-weight: 700;
      }
    }

    .account-container {
      width: 100%;
      background: rgba(25, 25, 25, 0.05);
      border: 1px solid rgba(25, 25, 25, 0.05); 
      border-radius: 8px;
      padding: 12px 32px 24px;

      img {
        margin-bottom: 8px;
      }

      .account-item-container {
        display: flex;

        .account-attribute {
          width: 120px;
        }
      }
    }
  }
}

