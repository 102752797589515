.custom-dropdown button.dropdown-toggle {
    border: solid 1px #dcdcdc !important;
    background: #ffffff !important;
    color: #191919 !important;
    border-radius: 8px;
    text-align: left;
    position: relative;
    width: 285px;
    box-shadow: none !important;
    padding: 9px 60px 10px 15px;
  }
  
  .custom-dropdown button.dropdown-toggle::after {
    transform: rotate(-45deg);
    border-left: solid 1px #191919;
    border-bottom: solid 1px #191919;
    border-right: 0;
    border-top: 0;
    height: 7px;
    width: 7px;
    position: absolute;
    top: 17px;
    right: 18px;
  }
  
  .custom-dropdown button.dropdown-toggle::before {
    content: "";
    width: 45px;
    position: absolute;
    height: 44px;
    top: 0;
    right: 0;
    background: transparent;
    border-left: solid 1px #dcdcdc;
  }
  
  .custom-dropdown .dropdown-menu {
    width: 285px;
    border-radius: 8px;
    margin-top: 0;
    padding: 0;
  }
  
  .custom-dropdown .dropdown-menu .dropdown-item {
    background: transparent !important;
    color: #191919 !important;
    position: relative;
  }
  
  .custom-dropdown .dropdown-menu button {
    border: none !important;
    border-bottom: solid 1px #dcdcdc !important;
    padding: 10px 0 10px 46px;
  }
  
  .custom-dropdown .dropdown-menu button:nth-last-child(1) {
    border: none !important;
  }
  
  .custom-dropdown .dropdown-menu .dropdown-item.active-dropdown-list {
    color: var(--main-dark-orange) !important;
  }
  
  .custom-dropdown .dropdown-menu .dropdown-item.active-dropdown-list::before {
    content: "";
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translate(0, -50%);
    width: 15px;
    height: 10px;
    background-image: url("../img/check-mark.png");
    background-position: center;
    background-size: 20px;
  }