.main-content-loading-screen {
    .lds-ring {
      width: 50px;
      height: 50px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      right: unset;
      
      div {
        border: 5px solid #fff;
        border-color: #ff7d00 transparent transparent transparent;
        width: 50px;
        height: 50px;
        margin: 0;
      }
    }
  }
  
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    top: -3px;
    right: -3px;
  }
  
  .lds-ring.centered-lds-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .lds-ring.centered-lds-ring div {
    margin: 0;
  }
  
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    margin: 6px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--main-dark-orange) transparent transparent transparent;
  }
  
  .lds-ring.white-lds-ring div {
    border-color: #ffffff transparent transparent transparent;
  }
  
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .lds-ring-wrapper {
    width: 100%;
    text-align: center;
  
    .lds-ring {
      top: 3px;
      right: unset;
  
      div {
        margin: 0;
      }
    }
  }