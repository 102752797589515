.custom-radio [type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-radio label {
  margin-bottom: 0;
}

.custom-radio [type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: inline-block;
  color: #191919;
}

.custom-radio [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: solid 1px #dcdcdc;
  border-radius: 100%;
  background: #fff;
}

.custom-radio [type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 16px;
  height: 16px;
  border: solid 5px var(--main-dark-orange);
  position: absolute;
  top: 4px;
  left: 0;
  border-radius: 100%;
  transition: all 0.3s ease;
}

.custom-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.custom-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}