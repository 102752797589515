#pagination-wrapper {
    width: 100%;
    text-align: right;
    height: 110px;
    line-height: 110px;

    ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            display: inline;
            // color: var(--main-dark-orange);
            color: rgba(25, 25, 25, 0.6);
            font-weight: 600;
            font-size: 12px;
            margin-right: 40px;
            cursor: pointer;
            position: relative;

            &:not(.pagination-button) {
                top: 6px;
            }

            span {
                position: absolute;
                top: -42px;
                display: inline-block;
                left: 50%;
                transform: translate(-50%, 0);
            }

            &.active {
                /* padding: 12px 20px; */
                border-radius: 4px;
                background: var(--main-dark-orange);
                color: white;
                margin: 0 20px 0 -20px;
                text-align: center;
                position: relative;
                width: 30px;
                height: 30px;
                display: inline-block;
                top: 16px;
                position: relative;

                span {
                    position: absolute;
                    color: #ffffff;
                    top: -40px;
                    display: inline-block;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
            }

            &.pagination-label {
                position: relative;
                top: 5px;

                svg {
                    height: 12px;
                }
            }
        }
    }
}

// #pagination-wrapper ul {
//     padding: 0;
//     margin: 0;
//     list-style: none;
// }

// #pagination-wrapper ul li {
//     display: inline;
//     color: var(--main-dark-orange);
//     font-weight: 600;
//     font-size: 17px;
//     margin-right: 40px;
//     cursor: pointer;
// }

// #pagination-wrapper ul li.active {
//     /* padding: 12px 20px; */
//     border-radius: 50%;
//     background: var(--main-dark-orange);
//     color: white;
//     margin: 0 20px 0 -20px;
//     text-align: center;
//     position: relative;
//     width: 46px;
//     height: 46px;
//     display: inline-block;
//     top: 16px;
//     position: relative;
// }

// #pagination-wrapper ul li.active span {
//     position: absolute;
//     color: #ffffff;
//     top: -32px;
//     display: inline-block;
//     left: 50%;
//     transform: translate(-50%, 0);
// }

// #pagination-wrapper ul li.pagination-label {
//     position: relative;
//     top: -1px;
// }