.custom-date-picker .DayPicker-Caption {
    position: relative;
  }
  
  .custom-date-picker .DayPicker-wrapper {
    border: none !important;
    box-shadow: none !important;
    outline: 0 !important;
  }
  
  .custom-date-picker .DayPicker-wrapper .DayPicker-Month {
    margin: 16px 12px 0;
  }
  
  .custom-date-picker .DayPicker-Caption div {
    font-weight: bold !important;
    text-align: center;
  }

  .custom-date-picker span.DayPicker-NavButton {
    outline: none;
  }
  
  .custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--prev {
    position: absolute;
    display: inline-block;
    margin: 0;
    left: 27px;
    top: 20px;
    z-index: 1;
    width: 20px;
    height: 20px;
    background-image: none;
  }
  
  .custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--prev::before {
    position: absolute;
    top: 4px;
    left: 0;
    content: "";
    border-left: solid 2px black;
    border-bottom: solid 2px black;
    transform: rotate(45deg);
    width: 12px;
    height: 12px;
  }
  
  .custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--next {
    position: absolute;
    display: inline-block;
    margin: 0;
    right: 19px;
    top: 20px;
    z-index: 1;
    width: 20px;
    height: 20px;
    background-image: none;
  }
  
  .custom-date-picker span.DayPicker-NavButton.DayPicker-NavButton--next::before {
    position: absolute;
    top: 4px;
    left: 0;
    content: "";
    border-right: solid 2px black;
    border-bottom: solid 2px black;
    transform: rotate(-45deg);
    width: 12px;
    height: 12px;
  }

  .custom-date-picker span.DayPicker-NavButton--interactionDisabled.DayPicker-NavButton--prev::before {
    border-left: solid 2px rgba(25, 25, 25, 0.4);
    border-bottom: solid 2px rgba(25, 25, 25, 0.4);
  }

  .custom-date-picker span.DayPicker-NavButton--interactionDisabled.DayPicker-NavButton--next::before {
    border-right: solid 2px rgba(25, 25, 25, 0.4);
    border-bottom: solid 2px rgba(25, 25, 25, 0.4);
  }
  
  .custom-date-picker .DayPicker-Day {
    font-weight: bold;
    width: 40px;
    height: 40px;
    text-align: center;
  }

  .custom-date-picker .DayPicker-Day {
    color: inherit;
    outline: none;
  }

  // day selected in range, not start, not end
  .custom-date-picker
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color:rgba(255, 126, 0, 0.05);
      border-radius: 0;
      color: var(--main-dark-orange);
  }

  // day selected only one
  .custom-date-picker
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: var(--main-dark-orange);
      color: white;
  }

  // day selected, not start, not end hover
  .custom-date-picker
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
      background-color: var(--main-dark-orange);
      color: white;
  }

  // day selected start
  .DayPicker:not(.today-selected)
    .DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      border-radius: 50% 0 0 50%;
      background-color: var(--main-dark-orange);
      color: white
  }

  // day selected end
  .DayPicker:not(.today-selected)
    .DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    border-radius: 0 50% 50% 0;
    background-color: var(--main-dark-orange);
    color: white
  }
  
  /* single date picker */