.preview-message-main-wrapper {
  .header-container {
    margin-bottom: 8px;
    align-items: unset;

    .preview-message-header-title {
      // padding-bottom: 16px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
      color: #191919;
      width: 100%;
      text-align: left;
      padding-top: 8px;

      svg {
        margin-right: 6px;
        position: relative;
        top: -2px;
      }
    }
  }

  .alert-component {
    margin-top: 12px;
    color: #0c5f99;
    background: #ebf7ff;
    border: solid 1px #0c5f99;
    width: 100%;

    svg {
      transform: unset;
      position: absolute;
      top: 12px;
    }
  }

  .custom-checkbox {
    padding-top: 12px;
    line-height: 21px;
    margin-bottom: 16px;

    label {
      font-size: 14px;
      letter-spacing: -0.2px;
      font-weight: normal;
      margin: 0;
    }
  }

  .preview-message-content-wrapper {
    .preview-container {
      border-radius: 4px;
      width: 100%;
      // height: 198px;
      // background: url('../../../assets/img/whatsapp-bg.png');
      display: flex;
      flex-direction: column;
      padding: 48px 24px 24px;
      align-items: center;

      .block-header-container {
        background: #e4f2f9;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        width: 74px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        .block-inner {
          background: rgba(25, 25, 25, 0.1);
          border-radius: 2px;
          width: 50px;
          height: 14px;
        }
      }

      .block-chat-container {
        background: #dcf8c7;
        border-radius: 8px 0px 8px 8px;
        max-width: 502px;
        align-self: flex-end;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .chat-wrapper {
          word-break: break-word;
          white-space: pre-wrap;
        }

        .chat-image-wrapper,
        .chat-image-wrapper img {
          width: 100%;
        }

        .send-info-wrapper {
          align-self: flex-end;
          display: flex;
          align-items: center;

          p {
            margin: 0;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            margin-right: 2px;
          }
        }
      }
    }
  }

  .main-button-40:disabled {
    color: rgba(25, 25, 25, 0.4) !important;
  }
}

.modal-sending-message {
  .modal-content {
    padding: 32px;
    border-radius: 8px;
    width: 518px;
  }

  .modal-body {
    padding: 0;

    p {
      text-align: center;
      margin: 40px 0 0 0;
    }

    .lds-ring {
      div {
        width: 54px;
        height: 54px;
        border: 6px solid #ffffff;
        border-color: var(--main-dark-orange) transparent transparent
          transparent;
      }
    }
  }
}

.send-message-error-toast {
 svg { margin-right: 11px; }
}

.modal-send-message-confirmation {
  .modal-content {
    width: 600px;
    padding: 32px;
    background: #FFFFFF;
    border: 1px solid rgba(25, 25, 25, 0.1);
    box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
    border-radius: 8px;
  }
  .modal-body {
    padding: 0 0 1rem 0;
  }
  .modal-footer {
    padding: 0;
    border-top: none;
  }
  .modal-footer>* {
    margin: .25rem !important;
  }
  .confirmation-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919;
    svg {
      margin-right: 7px;
      vertical-align: -2px;
    }
  }
  .confirmation-description {
    margin-bottom: unset;
  }
  button {
    padding: 14px 40px;
    width: 259px;
  }
}
