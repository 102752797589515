.organization-details-container {
    .organization-details-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;
    }

    .organization-details-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        .organization-details-title {
            margin: 24px 0 8px;
            font-size: 14px;
            letter-spacing: -0.4px;
        }

        .organization-details-value {
            margin: 0;
            font-size: 20px;
            letter-spacing: -0.6px;
            max-width: 100%;
            width: 400px;
        }
    }
}