.date-range-button {
    padding: 13px 30px 14px 45px !important;
    font-size: 16px;
    line-height: 1;
    position: relative;
    margin-left: 0 !important;
  }
  
.date-range-button svg {
    position: absolute;
    font-size: 17px;
    left: 22px;
    top: 12px;
}