.register-outer-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #FFAB3A 0%, #FF7E00 100%);
  overflow: auto;

  .display-flex-mobile {
    display: none !important;
  }

  .background-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    bottom: 0;
    background: url('../../../assets/img/auth/illustration-auth.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;

    .custom-checkbox {
      label {
        font-size: 14px;
        letter-spacing: -0.2px;
        margin-bottom: 32px;
      }
    }

    .header-container {
      margin: 0 0 36px;
      text-align: center;
  
      img {
        margin-bottom: 12px;
      }
  
      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #FFFFFF;
      }
    }
  
    .form-container {
      position: relative;
      width: 500px;
      background: #FFFFFF;
      border-radius: 12px;
      z-index: 1;
      padding: 48px;
      box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);

      .fail-panel {
        width: 100%;
        background: #FFE6E9;;
        border-radius: 8px;
        color: #ff3f57;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding: 12px 15px 10px;
        margin-bottom: 24px;
        text-align: left;
        border: 1px solid #FF3F57;
        box-shadow: 0px 2px 20px rgba(25, 25, 25, 0.18);
        display: flex;

        .icon-wrapper {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          position: relative;
          top: -2px;

          svg {
            font-size: 24px;
          }
        }
      }

      .points-container {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .points-item {
          display: flex;
          align-items: center;
          margin-bottom: 32px;

          svg {
            color: #19C700;
            font-size: 14px;
            margin-right: 8px;
          }

          b {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: -0.4px;
            color: rgba(25, 25, 25, 0.6);
          }
        }
      }
  
      .form-title {
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: -0.6px;
        color: #191919;
        margin-bottom: 32px;
      }
  
      .input-wrapper {
        position: relative;
        padding-bottom: 24px;

        .not-valid-email {
          font-size: 12px;
          color: #ff3f57;
          margin-top: 8px;

          svg {
            margin-right: 4px;
            position: relative;
            top: -1px;
          }
        }

        &.password {
          padding-bottom: 8px;
        }

        &.re-password {
          padding-bottom: 32px;

          input:disabled {
            background-color: rgba(25, 25, 25, 0.1);
            cursor: not-allowed;
          }

          .error-match-password {
            font-size: 12px;
            color: #ff3f57;
            margin-top: 8px;

            svg {
              margin-right: 4px;
              position: relative;
              top: -1px;
            }
          }
        }
  
        .popup-input-password-wrapper {
          .peek-password {
            top: 5px;
          }
  
          .active-eye {
            color: #ff7d00;
          }
        }
        
        label {
          font-size: 14px;
          letter-spacing: -0.4px;
          color: #191919;
          margin: 0;
        }
  
        input {
          margin: 8px 0 0;
          display: block;
          border-radius: 8px;
          border: solid 1px #dcdcdc;
          padding: 13px 15px 15px;
          font-size: 16px;
          width: 100%;
          box-sizing: border-box;
          letter-spacing: -0.2px;
  
          &.fail-state-input {
            border: solid 1px #ff3f57;
          }
  
          &::placeholder {
            color: #9b9b9b;
          }
  
          &:focus {
            border: solid 1px var(--main-dark-orange);
          }
        }
  
        .input-error {
          margin: 6px 12px;
          font-size: 13px;
          letter-spacing: 0.5px;
          font-weight: 600;
          color: rgba(255, 0, 0, 0.7);
        }
  
        .password-title-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
  
          .forgot-password {
            color: #ff7d00;
            cursor: pointer;
          }
        }
      }
  
      .signup-here {
        font-size: 16px;
        letter-spacing: -0.2px;
        color: #191919;
        text-align: center;
        margin-top: 32px;
        color: rgba(25, 25, 25, 0.5);
  
        .signup-link {
          margin-left: 4px;
          color: #ff7e00;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }
  }
}

.flex-center {
  justify-content: center !important;
}

.flex-space-evenly {
  justify-content: space-evenly !important;
}

// @function vh($quantity) {
//   @return calc(var(--vh, 1vh) * #{$quantity});
// }

@media screen and (max-width: 1025px) {
  .register-outer-container .background-container {
    background: url('../../../assets/img/auth/illustration-auth-tablet@2x.png') !important;
    // background-size: auto !important;
    // background-size: contain !important;
    background-size: 50% !important;
    background-repeat: no-repeat !important;
    background-position: left bottom !important;
    height: 100%;
    // height: 100vh; // fallback
    // height: vh(100);
  }
}

@media screen and (max-width: 480px) {
  .register-outer-container {
    .display-hide-mobile {
      display: none !important;
    }
    .display-flex-mobile {
      display: flex !important;
    }
    .background-container {
      background: unset !important;
    }
    .form-container {
      width: 343px !important;
      padding: 28px !important;
    }
    .form-title {
      font-size: 24px !important;
      line-height: 30px !important;
    }
    .points-item {
      margin-bottom: 16px !important;
    }
    .points-container.flex-center .points-item {
      margin-bottom: 24.5px !important;
    }
  } 
}