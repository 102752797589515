.case-modal-filter-option {
    width: 100%;
    
    .modal-content {
        width: 480px;
        
        .modal-body {
            padding: 32px 32px 0;

            .modal-date-filter-title {
                margin: 0;
                padding: 0;

                b {
                    padding: 0;
                }

                svg {
                    margin-right: 4px;
                    position: relative;
                    top: -2px;
                }
            }

            label {
                font-size: 14px;
                letter-spacing: -0.4px;
                margin-top: 24px;
                font-size: 12px;
                letter-spacing: -0.34px;
            }

            .case-filter-option-wrapper {
                margin-top: 0px;

                .filter-message-type-wrapper {
                    display: flex;
                    // flex: 1 1 0px;
                    justify-content: space-between;
                }

                .input-container {
                    display: flex;
                    align-items: center;
                    background: white;
                    border-radius: 8px;
                    border: 1px solid rgba(25, 25, 25, 0.1);
                    height: 48px;
                    width: 100%;
                    color: #191919;
                    font-size: 16px;
                    letter-spacing: -0.3px;
                    padding: 0 16px;
            
                    p {
                        margin: 0;
                    }
                    
                    input {
                        border: none;
                        background-color: transparent;
                        width: 100%;
            
                        &:focus {
                        border: none !important;
                        outline: none !important;
                        }
                    }

                    &.input-phone {
                        p {
                            font-size: 24px;
                        }
                    }
                }

                .input-info-wrapper {
                    margin: 0;
                    color: rgba(25, 25, 25, 0.8);

                    b {
                        font-size: 12px;
                    }
            
                    svg {
                        font-size: 16px;
                        margin-right: 4px;
                    }
                }

                .label-value {
                    font-size: 12px;
                    letter-spacing: -0.4px;
                    display: inline-block;
                    margin: 0 4px 4px 0;
                    vertical-align: top;
                    border-radius: 4px;
                    color: #ffffff;
                    padding: 2px 8px;
    
                    svg {
                        position: relative;
                        vertical-align: top;
                        margin-right: 4px;
                        top: 3px;
                        color: #ffffff;
                        cursor: pointer;
                    }
                }
            }

            .select-value-wrapper {
                border-radius: 8px;
                width: 100%;
                position: relative;
                font-size: 16px;
                letter-spacing: -0.3px;
                padding: 16px;
                border: solid 1px #dcdcdc;

                svg {
                    position: absolute;
                    right: 16px;
                    font-size: 24px;
                    top: 50%;
                    transform: translate(0, -50%);
                }
            }
        }

        .modal-footer {
            border: none;
            padding: 20px 32px 32px !important;
            display: flex;
            justify-content: space-between;

            .clear-wrapper {
                color: #FF7E00;
                cursor: pointer;

                svg {
                    position: relative;
                    top: -2px;
                    margin-right: 4px;
                }
            }

            .button-container {
                width: 60%;
                display: flex;
                justify-content: flex-end;

                button {
                    width: calc(50% - 4px);
                    margin: 0 8px 0 0;

                    &.main-button-40 {
                        padding: 0 24px !important;
                    }

                    &.apply-filter {
                        width: 132px;
                    }

                    &.cancel-filter {
                        width: 96px;
                    }
    
                    &:nth-last-child(1) {
                        margin: 0;
                    }
                }
            }

        }
    }
}