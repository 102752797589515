.api-key-main-wrapper {
  .btn {
      border: 0;
  }

  thead {
    tr {
      td {
        &:nth-child(2) {
          width: 15%;
        }

        &:nth-child(3) {
          width: 20%;
        }

        &:nth-child(1) {
          width: 40%;
        }
  
        &:last-child {
          width: 90px;
        }
      }
    }
  }

  tbody {
    tr td:not(.no-data-column) {
      padding: 8px 10px;
    }
    
    .col_key {
        color: #ff7e00;
        cursor: pointer;

        svg {
            color: #ff7e00;
            position: relative;
            top: -2px;
            right: unset;
            margin-right: 4px;
            font-size: 16px;
        }
    }
  }
}

.api-key-list-title {
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
  line-height: 24px;
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
}

.api-key-list-tool-wrapper {
  width: 100%;
  position: relative;
  margin-top: 24px;
  margin-bottom: 32px;
}

.api-key-list-info {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: rgba(25, 25, 25, 0.8);
  line-height: 1.5;
  padding: 0 208px 18px 0;
  border-bottom: 1px solid rgba(25, 25, 25, 0.1);
  margin-bottom: 24px;
}

.api-key-list-tool-wrapper button.button-invite-member {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  padding: 11px 0;
  width: 200px;
}

.api-key-list-tool-wrapper button.button-invite-member svg {
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.no-data-wrapper {
  width: 100%;
  text-align: center;
  color: rgba(25, 25, 25, 0.6);
}

.api-key-list-table-wrapper {
  width: 100%;
  position: relative;
}

.table-api-key-list thead tr {
  background: #ffffff;
  // border-top: solid 1px #dcdcdc;
}

.table-api-key-list tbody td {
  // background: #ffffff;
  position: relative;
}

.table-api-key-list tbody tr {
  // background: #ffffff;
  // position: relative;

  &:nth-child(even) {
    background: rgba(25, 25, 25, 0.05);
  }
}

.table-api-key-list button svg {
  position: relative !important;
  right: unset !important;
  top: -2px !important;
  margin-right: 5px;
}

.table-api-key-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
  /* display: none; */
}

.table-api-key-list .single-remove-button {
  margin-left: 16px;
  color: #ff3f57;

  svg {
    color: #ff3f57;
    font-size: 16px;
  }
}

.table-api-key-list .single-remove-button:hover {
  color: #e83b51;
}

.table-api-key-list .shortcut-badge {
  font-size: 12px;
  color: rgba(25, 25, 25, 0.6);
  letter-spacing: -0.4px;
  background: #f3f3f3;
  line-height: 1;
  border-radius: 4px;
  padding: 1px 8px 3px;
}

.table-api-key-list td {
  max-width: unset !important;
}

.table-api-key-list .col-action-button-wrapper {
  display: block !important;
  position: relative;
  top: 0;
  right: unset;
  transform: unset;
}

.table-api-key-list .col-action-button-wrapper .view-agent {
  color: #ff7d00;
  letter-spacing: -0.5px;
}

.table-api-key-list .col-action-button-wrapper .view-agent:hover {
  color: var(--main-darker-orange);
}

.table-api-key-list .col-action-button-wrapper img.view-agent {
  margin-right: 3px;
  position: relative;
  top: -1px;
}

.member-tool-wrapper {
  width: 460px;
}

.table-api-key-list {
  .col_action {
      width: 200px;
      text-align: right;
  }
}

.modal-view-key {
  .modal-content {
      width: 476px;
      border-radius: 8px;

      .modal-body {
          text-align: left;
          padding: 32px;

          .disabled-form {
              position: relative;

              p {
                  top: 0;
                  left: 0;
                  border: 1px solid rgba(25, 25, 25, 0.1) !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  padding: 0 46px 0 16px;
                  border-radius: 8px;
                  background: #f3f3f3;
                  margin: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  padding-top: 10px;
              }
          }

          .modal-title {
              font-size: 24px;
              line-height: 28px;
              letter-spacing: -0.6px;
              margin-bottom: 8px;
              margin: 0 0 24px 0;

              svg {
                  margin-right: 4px;
                  position: relative;
                  top: -1px;
              }
          }

          .disabled-form {
              width: 100%;
              
              input {
                  width: 100%;
              }

              svg {
                position: absolute;
                font-size: 24px;
                cursor: pointer;
                z-index: 1;
                right: 16px;
                top: 50%;
                transform: translate(0, -50%);
              }
          }

          button {
              width: 100%;
              margin-top: 20px;
          }
      }
  }
}