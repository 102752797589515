.topup-now-main-wrapper {
  iframe {
    width: 100%;
    height: 600px;
    border: solid 1px #dcdcdc;
    border-radius: 8px;
  }

  .topup-now-header-title {
    padding-bottom: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.6px;
    color: #191919;
    width: 100%;

    &.single-title {
      border-bottom: 1px solid rgba(25, 25, 25, 0.1);
      margin-bottom: 24px;
    }
  }

  &.topup-now-pay-main-wrapper {
    .topup-now-header-title {
      border-bottom: solid 1px #dcdcdc;
      padding-bottom: 12px;
      margin-bottom: 24px;
      width: 100%;
      display: block;
    }

    .topup-now-pay-bottom {
      width: 100%;
      border-radius: 8px;
      display: block;

      .topup-now-pay-bottom-title {
        background: #ff7e00;
        color: #ffffff;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}

