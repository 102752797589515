.whatsapp-verification-container {
    .whatsapp-verification-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;

        button {
            width: 180px;
        }
    }

    .whatsapp-verification-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        .custom-switch-wrapper {
            display: inline-block !important;
            width: 40px;
            height: 24px;
            position: absolute;
            top: 14px;
            right: 0;
            margin: 0;
    
            .custom-switch.round {
                &::before {
                    width: 14px;
                    height: 14px;
                    bottom: 5px;
                    left: 4px;
                }
            }
    
            input:checked + .custom-switch:before {
                transform: translateX(18px);
            }
        }

        .whatsapp-verification-title {
            margin: 24px 0 8px;
            font-size: 16px;
            letter-spacing: -0.5px;

            span {
                font-size: 14px;
                letter-spacing: -0.2px;
                color: rgba(25, 25, 25, 0.8);
                display: block;
                margin-top: 4px;

                a {
                    color: var(--main-dark-orange);

                    &:hover {
                        color: var(--main-darker-orange);
                    }
                }
            }
        }

        .whatsapp-verification-value {
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            max-width: 100%;
            width: 400px;

            b {

                img {
                    position: relative;
                    top: -1px;
                    margin-right: 2px;
                }
            }
        }

        input[type="text"] {
            padding: 12px 16px;
            width: 100%;
            border-radius: 8px;
            border: solid 1px rgba(25, 25, 25, 0.1);
        }
    }
}