.custom-checkbox [type="checkbox"]:not(:checked),
.custom-checkbox [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.custom-checkbox [type="checkbox"]:not(:checked) + label,
.custom-checkbox [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
}

.custom-checkbox [type="checkbox"]:not(:checked) + label:before,
.custom-checkbox [type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border: 1px solid #dcdcdc;
  background: #ffffff;
  border-radius: 4px;
}

.custom-checkbox [type="checkbox"]:not(:checked) + label:after,
.custom-checkbox [type="checkbox"]:checked + label:after {
  content: "";
  background-image: url("../img/icon-for-checkbox.svg");
  position: absolute;
  /* content: "\f007"; */
  // font-family: FontAwesome;
  // font-weight: 900;
  background-size: cover;
  background-repeat: no-repeat;
  top: -3px;
  left: -5px;
  width: 26px;
  height: 26px;
  // background: var(--main-dark-orange);
  font-size: 10px;
  line-height: 17px;
  text-align: center;
  color: white;
  border-radius: 4px;
  font-weight: normal;
  // transition: 0.5s ease-in-out;
}

.custom-checkbox [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

.custom-checkbox [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.custom-checkbox label:hover:before {
  border: 2px solid var(--main-dark-orange) !important;
}