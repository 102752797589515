.modal-backdrop.show {
  background: #191919 !important;
}

.modal-dialog {
  /* width: 100vw !important; */
  margin: 0 auto !important;
  height: 100vh;
}

.modal-content {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  box-shadow: var(--main-box-shadow);
}

.modal.fade.show {
    width: 100vw;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(25, 25, 25, 0.6);
}

.modal-footer>* {
  margin: unset;
}

.modal-backdrop.show {
    background: #191919 !important;
}

.modal-dialog {
    /* width: 100vw !important; */
  margin: 0 auto !important;
    height: 100vh;
    position: relative !important;
    top: 0;
  left: 0;
  max-width: unset !important;
  width: 500px !important;
}

.modal-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    box-shadow: var(--main-box-shadow);
    background: #ffffff;
}

.modal-image.modal-dialog {
  width: 100vw !important;
}  
.modal-image .modal-content {
  width: 100%;
  height: 100%;
  background: none;
}
.modal-image .modal-content .modal-body {
  width: 100%;
  height: 100%;
  background: rgba(25, 25, 25, 0.8);
}
.modal-image .modal-content .modal-body img.image-preview-val {
  max-width: calc(100vw - 130px);
  max-height: calc(100vh - 130px);
}

.modal-image .modal-content .modal-body .image-wrapper {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  figure {
    margin: 0;
    
    &.zoom {
      background-size: 180%;
      background-position: -100% -100%;
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: zoom-in;

      img{
        transition: opacity 0.5s;
        display: inline-block;
        // width: 100%;

        &:hover {
          opacity: 0;
        }
      }
    }   
  }
}

.modal-image .modal-content .modal-body .close-modal-image {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  font-size: 40px;
  cursor: pointer;
  transition: ease-in-out 0.1s;
}
.modal-image .modal-content .modal-body .close-modal-image:hover {
  color: #c9c9c9;
}
