.cost-report-table-wrapper {
  margin-top: 24px;
}
.cost-report-main-wrapper {
  .cost-report-header {
    display: flex;
    margin-bottom: 24px !important;
    border-bottom: 1px solid rgba(25, 25, 25, 0.1);
    justify-content: space-between;

    .cost-report-header-title {
      padding-bottom: 8px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.6px;
      color: #191919;
      width: 100%;
  
      &.single-title {
        border-bottom: 1px solid rgba(25, 25, 25, 0.1);
        margin-bottom: 24px;
      }
    }
  
    .cost-report-header-description {
      font-size: 16px;
      letter-spacing: -0.3px;
      color: rgba(25, 25, 25, 0.8);
      line-height: 1.5;
      padding: 0 0 12px 0;
      margin-bottom: 0 !important;
      width: 100%;
    }
  }
}

.table-cost-report-list {
  table-layout: fixed;

  thead {
    tr {
      background: transparent;
  
      td {
        padding: 8px;
        width: 20%;
        font-size: 14px;
        word-wrap: break-word;
        white-space: normal;
        text-align: center;
        position: relative;

        svg {
          position: relative;
          top: -2px;
        }

        .question-circle-wrapper {
            margin-left: 5px;
            position: absolute;
            top: 25%;
        }

        &:nth-child(1) {
          text-align: left;
        }

        &:nth-child(6) {
          text-align: left;
        }
  
        &:last-child {
          width: 90px;
  
          .custom-select-option-value-wrapper {
            width: 55px !important;
            height: 23px;
            padding: 2px 8px 2px 12px !important;
            border-radius: 4px !important;

            p {
              font-size: 12px;
              font-weight: bold;
            }
  
            .custom-select-option-value {
              padding: 0;
            }
  
            svg {
              right: 3px;
              height: 15px;
              top: 11px;
            }
          }
        }
  
      }
    }
  }

  tbody {
    tr {
      // cursor: pointer;

      &:hover {
        background-color: unset;

        td {
          color: unset;
        }
      }

      td:not(.no-data-column) {
        padding: 8px 10px;
        p {
          margin-bottom: 0;
        }
      }

      td {
        background: transparent;
        position: relative;
        word-wrap: break-word;
        vertical-align: top;
        text-align: center;

        .unavailable {
          color: rgba(25, 25, 25, 0.4);
        }

        .link-user {
          color: inherit;
          text-decoration: inherit;
        }

        &:nth-child(1) {
          text-align: left;

          .col_name_alias {
            font-size: 12px;
          }
        }

        &:nth-child(4) {
          &.col-origin {
            p {
              margin: 0;
              display: inline;
            }

            img {
              position: relative;
              top: -2px;
              margin-right: 8px;  
            }
          }
        }

        &:nth-child(6) {
            text-align: left;
        }

        &.col-transaction {
          input {
            background: transparent;
            border: none;
            width: 90%;
            text-overflow: ellipsis;
            color: rgba(25, 25, 25, 0.8);
          }
          svg {
            position: relative;
            cursor: pointer;
            color: rgba(25, 25, 25, 0.8);
            font-size: 16px;
            margin-right: 4px;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}

.table-cost-report-list tbody tr {
  &:nth-child(even) {
    background: rgba(25, 25, 25, 0.05);
  }
}

.table-cost-report-list .col-action-button-wrapper {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(0, -50%);
}

.table-cost-report-list td {
  max-width: unset !important;
}

