.whatsapp-verification-history-container {
    .whatsapp-verification-history-top-content {
        border-bottom: solid 1px #dcdcdc;
        padding-bottom: 12px;
        margin-bottom: 24px;

        button {
            width: 180px;
        }
    }

    .button-clear-selection {
        border: none !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        min-width: unset !important;
        background: none !important;
        position: relative;
        top: 2px;
    }

    .whatsapp-verification-history-bottom-content {
        color: #191919;
        position: relative;
        width: 100%;
        display: block;

        table {
            margin-top: 24px;
            border-collapse: collapse;
            width: 100%;

            thead {
                border-bottom: solid 1px rgba(25, 25, 25, 0.4);
                vertical-align: top;

                td {
                    padding: 8px;
                    font-size: 14px;
                    letter-spacing: -0.4px;
                }

                .custom-select-option-value-wrapper {
                    width: 55px !important;
                    height: 23px;
                    padding: 2px 8px 2px 12px !important;
                    border-radius: 4px !important;
        
                    p {
                      font-size: 12px;
                      font-weight: bold;
                    }
          
                    .custom-select-option-value {
                      padding: 0;
                    }
          
                    svg {
                      right: 3px;
                      height: 15px;
                      top: 11px;
                    }
                }
            }

            tbody {
                tr {
                    &:nth-child(even) {
                        background: #f3f3f3;
                    }
                }

                td {
                    padding: 8px;
                    font-size: 14px;
                    letter-spacing: -0.2px;
                    vertical-align: top;
                    
                    .nav-link {
                        padding: 0;
                    }

                    .button-details {
                        color: var(--main-dark-orange);
                        white-space: nowrap;
                        cursor: pointer;

                        svg {
                            position: relative;
                            top: -1px;
                            margin-right: 4px;
                        }

                        &:hover {
                            color: var(--main-darker-orange);
                        }
                    }

                    &.td-status {
                        white-space: nowrap;

                        img {
                            position: relative;
                            top: -1px;
                            margin-right: 4px;
                        }
                    }
                }
            }

            .no-verification-history-found {
                color: rgba(25, 25, 25, 0.4);
                text-align: center;
                padding: 24px 24px 12px;
            }

            .td-loadings {
                text-align: center;
                padding: 0 24px 12px;
            }

            .lds-ring {
                width: 54px !important;
                height: 54px !important;

                div {
                    width: 54px !important;
                    height: 54px !important;
                    border: solid 6px transparent !important;
                    border-color: #ff7d00 transparent transparent transparent !important;
                }
            }
        }

        .no-fill-button, .orange-button {
            min-width: 180px;
            margin-right: 8px;
        }

        .whatsapp-verification-history-title {
            margin: 24px 0 8px;
            font-size: 16px;
            letter-spacing: -0.5px;
        }

        .whatsapp-verification-history-value {
            margin: 0;
            font-size: 16px;
            letter-spacing: -0.5px;
            max-width: 100%;
            width: 400px;

            b {

                img {
                    position: relative;
                    top: -1px;
                    margin-right: 2px;
                }
            }
        }
    }
}