@mixin submenuOrange {
	color: #ff7d00;
	background: rgba(255, 126, 0, 0.1);
}

@mixin submenuGrey {
	color: rgba(25, 25, 25, 0.4);
	background: rgba(25, 25, 25, 0.05);
}

#sidemenu-wrapper {
	width: 200px;
	display: inline-block;
	position: fixed;
	top: 0;
	background: var(--main-dark-orange);
	color: #ffffff;
	height: calc(100vh);
	overflow-x: hidden;
	box-shadow: 2px 2px 5px 0 #c3c3c3;
	z-index: 1;
	font-weight: 100;

	a.active-menu {
		div {
			color: #ffffff !important;
			opacity: 1 !important;
			font-weight: bold;
		}
		li {
			color: #ffffff !important;
			opacity: 1 !important;
			font-weight: bold;
		}
	}
	.menu-padding {
		padding: 0 16px;
	}
	.menu-height {
		line-height: 70px;
		height: 70px;
	}
	.list-menu-wrapper {
		position: relative;
		font-weight: bold;
		cursor: pointer;
		img {
			margin-right: 4px;
			position: relative;
			top: -2px;
			width: 18px;
		}
	}
	.menu-smaller {
		color: #ffffff;
		opacity: 0.8;
		font-size: 14px;
		font-weight: 100;
	}
	.menu-smaller.active-side-menu {
		background: #de6d00;
	}
	.parentMenu-with-child {
		font-size: 16px;
		.parentMenu-name {
			position: relative;
			border-bottom: solid 1px #E87200;
		}
		.childMenu-wrapper {
			position: relative;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				position: relative;
				background: #E87200;
				li {
					border-bottom: solid 1px #E87200;
				}
			}
		}
	}
	.parentMenu-with-child.active {
		background: var(--main-dark-orange);
		.parentMenu-name {
			border-bottom: solid 1px #E87200;
		}
		.childMenu-wrapper {
			ul {
				li {
					border-bottom: solid 1px #fb7b00;
				}
			}
		}
	}
	.menu-hover {
		&:hover {
			background: #de6d00 ! important;
		}
	}
}
.menu-Home {
	border-top: 1px solid rgb(232, 114, 0);
}
.sidemenu-organization-wrapper {
	position: relative;
	margin-bottom: 24px;
	padding: 12px 16px 14px 16px;
	box-shadow: 0 1px 0 0 #e87200;
}
.side-organization-name {
	margin: 0;
	font-size: 14px;
	line-height: 1.5;
	letter-spacing: -0.4px;
	color: #ffffff;
	max-width: calc(100% - 30px);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.side-organization-email {
	margin: 0;
	font-size: 12px;
	line-height: 1.33;
	letter-spacing: -0.2px;
	color: #ffffff;
	font-weight: normal;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.right-arrow-organization {
	position: absolute;
	right: 12px;
	top: 14px;
}
.organization-popup-wrapper {
	position: fixed;
	transition: ease-in-out .2s;
	background: #ffffff;
	width: 280px;
	// height: 293px;
	max-height: 363px;
	display: inline-block;
	left: 80px;
	bottom: 16px;
	border-radius: 8px;
	box-shadow: 0 0 20px 2px #eaeaea;
	// cursor: pointer;
	visibility: visible;
	opacity: 1;
	z-index: 1;
	// overflow: hidden;
	
	&:before {
		content: "";
		position: absolute;
		width: 16px;
		height: 16px;
		transform: rotate(45deg);
		bottom: 18px;
		left: -8px;
		box-shadow: -4px 5px 5px 0px #eaeaea;
		background: #ffffff;
	}
}
.organization-info-container {
	overflow: hidden;
	position: absolute;
	width: 280px;
	height: 293px;
}
.organization-list-container {
	overflow: hidden;
	// position: absolute;
	width: 280px;
	// max-height: 363px;
	// padding: 0 24px;

	.back-container {
		padding: 16px 24px 8px;
		border-bottom: solid 1px #dcdcdc;
		border-radius: 8px 8px 0 0;
		color: rgba(25, 25, 25, 0.4);
		cursor: pointer;

		&:hover {
			color: #FF7E00;
		}

		svg {
			position: relative;
			top: -2px;
			margin-right: 4px;
			font-size: 20px;
		}

		b {
			font-size: 16px;
		}
	}
	
	.organization-list-items {
		max-height: 270px;
		padding: 12px 24px 12px 16px;
		border-radius: 0;
		border-bottom: solid 1px #dcdcdc;
		overflow-y: auto;
	
		ul {
			margin: 0;
			padding: 0;
			overflow-y: hidden;
	
			li {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					font-size: 14px;
					color: rgba(25, 25, 25, 0.4);
					list-style: none;
					margin: 0;
					position: relative;
					font-weight: normal;
					display: flex;
					align-items: center;

					p {
						margin: 0;
						width: 100%;
					}
	
					&:hover {
						color: #ff7d00;
						cursor: pointer;
					}

					svg {
						color: #ff7d00;
						font-size: 34px;
						position: relative;
						top: -2px;
						visibility: hidden;
						align-self: flex-start;
					}
				}

				&.active-org {
					color: #ff7d00;
					p {
						color: #ff7d00;
					}
					svg {
						visibility: visible;
					}
				}
			}
		}
	}

	.new-org-wrapper {
		padding: 12px 40px;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.5px;
		color: rgba(25, 25, 25, 0.4);

		&:hover {
			cursor: pointer;
			color: #FF7E00;
		}

		svg {
			position: relative;
			top: -2px;
			margin-right: 8px;
		}
	}
}

.open-org-list {
	animation: slide 0.5s forwards;
	position: absolute;
	bottom: 0;
	background: #fff;
	width: 100%;
	border-radius: 8px;
}

.show-org-list {
	animation: slide-in 0.5s forwards;
	// position: absolute;
	bottom: 0;
	background: #fff;
	width: 100%;
	border-radius: 8px;
}

.close-org-list {
	animation: slide-right 0.5s forwards;
	bottom: 0;
	background: #fff;
	width: 100%;
	border-radius: 8px;
}

.hide-org-list {
	display: none;
}

@keyframes slide {
	0% { 
		left: 0; 
	}
	100% { 
		left: -100%; 
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes slide-right {
	0% { 
		left: -100%; 
		opacity: 0;
		visibility: hidden;
	}
	100% { 
		left: 0;
	}
}

@keyframes slide-in {
	0% { 
		left: 100%; 
		opacity: 0;
		visibility: hidden; 
	}
	100% { 
		left: 0; 
		// opacity: 1;
		// visibility: visible;
	}
}

.popup-organization-content-info {
	padding: 19px 20px;
	line-height: 1;
	border-bottom: solid 1px #dcdcdc;
	position: relative;
	p {
		color: #191919;
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		line-height: 15px;
	}
	&.popup-top-content {
		padding: 20px;
		position: relative;
		
		p:nth-child(1) {
			margin-bottom: 10px;
		}

		svg {
			color: #ff7d00;
			font-size: 20px;
			position: absolute;
			top: 19px;
			right: 20px;

		

			&:hover {
				color: #e87200;
			}
		}
	}
}
.popup-organization-content-info-name {
	font-size: 16px;
	margin: 0 0 4px 0;
}
.popup-organization-content-info-email {
	font-weight: normal;
	font-size: 14px;
	margin: 0;
}
.popup-oganization-list {
	// height: 183px;
	// height: 100%;
	padding: 16px 0 12px;
	max-height: 183px;
	overflow: auto;
	position: relative;

	.title-info-organization {
		padding: 0 24px;
		margin: 0;
		font-size: 10px;
		line-height: 15px;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: rgba(25, 25, 25, 0.6);
		margin-bottom: 8px;
	}

	.footer-info-organization {
		padding: 0 24px;
		margin: 0;
		font-size: 12px;
		line-height: 16px;
		letter-spacing: -0.2px;
		color: rgba(25, 25, 25, 0.6);
		margin-top: 4px;
	}
	
	&.organization-list-with-scrollbar {
		height: 183px;
	}

	ul {
		margin: 0;
		padding: 0;
		// max-height: 112px;
		overflow-y: hidden;

		li {
			font-size: 14px;
			color: #191919;
			list-style: none;
			margin: 0;
			position: relative;
			font-weight: normal;
			
			svg {
				color: #ff7d00;
				font-size: 16px;
				position: absolute;
				right: 15px;
				top: 0;
				color: rgba(25, 25, 25, 0.6);
			}

			&:hover {
				color: #ff7d00;
				cursor: pointer;
				svg {
					color: #ff7d00;
				}
			}
		}
		li.active-organization {
			color: #ff7d00;
			svg {
				display: block;
			}
		}
	}
	a {
		color: unset !important;
	}
}
.organization-link-wrapper {
	padding: 0 24px;
}
.organization-popup-wrapper.deactive-organization-popup-wrapper {
	visibility: hidden;
	opacity: 0;
	// transition: visibility 0s, opacity 0.5s linear;
}
.popup-organization-content-wrapper {
	padding: 0;
	line-height: 59px;
	height: 60px;
	width: 100%;
	position: relative;	
	
	b {
		position: relative;
	}
	svg {
		font-size: 25px;
		position: relative;
		top: -2px;
		// top: 50%;
		// transform: translate(0, -50%);
		// position: absolute;
	}
}
.new-organization-wrapper {
	font-size: 16px;
	color: #ff7d00;
	text-align: center;
	border-top: solid 1px #dcdcdc;
	position: relative;
}
.account-wrapper {
	font-size: 16px;
	color: rgba(25, 25, 25, 0.4);;
	text-align: left;
	border-top: solid 1px #dcdcdc;
	padding: 20px;
	line-height: 24px;

	svg {
		font-size: 16px;
		margin-right: 8px;
	}

	&:hover {
		color: #ff7d00;
		cursor: pointer;
		svg {
			color: #ff7d00;
		}
	}
}
.logout-wrapper {
	font-size: 16px;
	color: #ff3f57;
	text-align: left;
	cursor: pointer;
	padding: 20px;
	line-height: 0;
	svg {
		font-size: 16px;
		margin-right: 8px;
	}
}


.new-side-menu-wrapper {
	max-height: 100%;
	width: 62px;
	display: inline-block;
	position: relative;
	overflow-y: auto;
	background: #ff7d00;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	z-index: 2;
	box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
	
	&.side-menu-active-billing-warning {
		top: 32px;
		height: calc(100% - 32px);
		max-height: calc(100% - 32px);
	}

	.new-main-side-menu-wrapper {
		color: #ffffff;
		line-height: 12px;
		font-size: 10px;
		text-align: center;
		letter-spacing: -0.3px;
		padding: 0 4px 6px 3px;
		cursor: pointer;
		width: 100%;
		position: relative;

		img {
			margin: 12px 0 8px;
		}

		&:hover {
			background: #E87403;
		}

		&.active-side-menu {
			background: #E87403;

			.active-white-line {
				position: absolute;
				top: 12px;
				left: -1px;
				width: 3px;
				height: 32px;
				display: inline-block;
				background: #ffffff;
				box-shadow: 0 2px 20px 3px #ffffff;
				border-radius: 8px;
			}
		}
	}

	.side-menu-main-bottom-wrapper {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		display: inline-block;

		.new-main-side-menu-wrapper {
			line-height: 12px;
			padding: 0 4px 10px 3px;

			img {
				margin: 12px 0 10px;
			}
		}

		.my-account-avatar {
			border-radius: 50%;
			width: 32px;
			height: 32px;
			margin: 12px 0 10px;

			&.avatar-name {
				background: #ffffff;
				color: #ff7d00;
				width: 32px;
				height: 32px;
				display: inline-block;
				line-height: 32px;
				font-size: 15px;
			}

			&.image {
				width: 100%;
			}
		}
	}
}

.new-sub-side-menu-wrapper {
	width: 278px;
	display: inline-block;
	height: 100%;
	position: fixed;
	top: 0;
	left: 62px;
	background: #ffffff;
	z-index: 1;
	padding: 12px;
	transition: ease-in-out .1s;

	.new-sub-side-menu-balance {
		position: absolute;
		left: 28px;
		bottom: 12px;
		width: 100%;

		.title-balance {
			font-size: 12px;
			letter-spacing: -0.4px;
			color: #191919;
		}

		.balance {
			display: block;
			width: calc(100% - 56px) !important;
			margin: 0;
			width: 16px;
			color: #ff7d00;
			letter-spacing: -0.5px;
			line-height: 24px;
			white-space: nowrap;
		}
	}

	.your-channel-limit {
		width: 100%;
		position: absolute;
		bottom: 12px;
		left: 16px;

		p {
			color: #191919;
			font-size: 12px;
			letter-spacing: -0.4px;
			margin: 0 0 0;
		}

		.channel-limit {
			color: #ff7d00;
			font-size: 16px;
			letter-spacing: -0.5px;
		}
	}

	&.new-sub-side-menu-active-billing-warning {
		top: 32px;
		height: calc(100% - 32px);
	}	

	&.deactive-submenu-wrapper {
		width: 0;
		padding: 0;

		.new-sub-side-menu-balance {
			display: none;
		}
	}

	.sub-menu-content {
		color: rgba(25, 25, 25, 0.4);
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.5px;
		padding: 12px 16px 12px 40px;
		margin-bottom: 12px;
		border-radius: 6px;
		cursor: pointer;
		position: relative;

		&.sub-menu-overflow {
			padding: 12px 41px 12px 40px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.medium-counter {
			letter-spacing: -0.4px;
			min-width: 20px;
			height: 20px;
			line-height: 20px;
			padding: 0 4px;
			background: #ff7d00;
			color: #ffffff;
			border-radius: 50%;
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translate(0, -50%);
			font-size: 12px;
			text-align: center;
		}

		.active-sub-side-menu {
			color: #ff7d00;
			background: rgba(255, 126, 0, 0.1);
		}

		svg {
			position: relative;
			left: 122px;
    	top: -2px;
		}

		.sub-menu-icon {
			position: absolute;
			width: 16px;
			height: 16px;
			content: "";
			left: 16px;
			top: 50%;
			transform: translate(0, -50%);
			background-repeat: no-repeat;
    		background-size: cover;

			&.secretKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/secret-keys-grey.svg');
			}

			&.pushNotification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/push-notif-grey.svg');
			}

			&.profile {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/profile-grey.svg');
			}

			&.members {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/member-grey.svg');
			}

			&.setup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/setup-grey.svg');
			}

			&.topics {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-grey.svg');
			}

			&.security {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/lock-grey.svg');
			}

			&.generalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/general-quick-reply-grey.svg');
			}

			&.personalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/personal-quick-reply-grey.svg');
			}

			&.billing {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/billing-grey.svg');
			}

			&.labels {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/tag-grey.svg');
			}

			&.broadcastMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg');
			}

			&.userContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-contact-grey.svg');
			}
			
			&.userSegment {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-segment-grey.svg');
			}

			&.topUpNow {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topup-now-grey.svg');
			}

			&.topUpHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topup-history-grey.svg');
			}

			&.lowBalanceAlert {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/low-balance-grey.svg');
			}

			&.sendMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/send-message-grey.svg');
			}

			&.messageHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-history-grey.svg');
			}

			&.apiDocs {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/api-docs-grey.svg');
			}

			&.changePassword {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/change-password-grey.svg');
			}

			&.organizationDetails {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/organization-details-grey.svg');	
			}

			&.verification_config {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/verification-config-grey.svg');
			}

			&.create_verification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/create-verification-grey.svg');
			}

			&.verification_history {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/verification-history-grey.svg');
			}
		}

		&:hover {
			@include submenuGrey;

			.secretKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/secret-keys-grey.svg');
			}

			.pushNotification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/push-notif-grey.svg');
			}

			.profile {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/profile-grey.svg');
			}

			.members {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/member-grey.svg');
			}

			.setup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/setup-grey.svg');
			}

			.topics {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-grey.svg');
			}

			.security {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/lock-grey.svg');
			}

			.generalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/general-quick-reply-grey.svg');
			}

			.personalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/personal-quick-reply-grey.svg');
			}

			.billing {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/billing-grey.svg');
			}

			.labels {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/tag-grey.svg');
			}

			.broadcastMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg');
			}

			.userContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-contact-grey.svg');
			}

			.userSegment {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-segment-grey.svg');
			}

			.topUpNow {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topup-now-grey.svg');
			}

			.topUpHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topup-history-grey.svg');
			}

			.lowBalanceAlert {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/low-balance-grey.svg');
			}
			
			.sendMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/send-message-grey.svg');
			}

			.messageHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-history-grey.svg');
			}

			.apiDocs {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/api-docs-grey.svg');
			}
			
			.changePassword {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/change-password-grey.svg');
			}

			.organizationDetails {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/organization-details-grey.svg');	
			}

			.verification_config {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/verification-config-grey.svg');
			}

			.create_verification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/create-verification-grey.svg');
			}

			.verification_history {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/verification-history-grey.svg');
			}
		}

		&.active-sub-menu {
			@include submenuOrange;

			.secretKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/secret-keys-orange.svg');
			}

			.pushNotification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/push-notif-orange.svg');
			}

			.profile {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/profile-orange.svg');
			}

			.members {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/member-orange.svg');
			}

			.setup {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/setup-orange.svg');
			}

			.topics {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topic-orange.svg');
			}

			.security {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/lock-orange.svg');
			}

			.generalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/general-quick-reply-orange.svg');
			}

			.personalQuickReply {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/personal-quick-reply-orange.svg');
			}
			
			.billing {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/billing-orange.svg');
			}

			.labels {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/tag-orange.svg');
			}

			.broadcastMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/broadcast-message-orange.svg');
			}

			.userContact {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-contact-orange.svg');
			}

			.userSegment {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/user-segment-orange.svg');
			}

			.apiKeys {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/api-key-orange.svg');
			}

			.topUpNow {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topup-now-orange.svg');
			}

			.topUpHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/topup-history-orange.svg');
			}

			.sendMessage {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/send-message-orange.svg');
			}

			.messageHistory {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/message-history-orange.svg');
			}

			.changePassword {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/change-password-orange.svg');
			}

			.organizationDetails {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/organization-details-orange.svg');	
			}

			.verification_config {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/verification-config-orange.svg');
			}

			.create_verification {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/create-verification-orange.svg');
			}

			.verification_history {
				background-image: url('../../../assets/img/sidemenu-icon/new/sub/verification-history-orange.svg');
			}
		}

		&.integration-submenu {
			padding-left: 44px;
			
			.sub-menu-icon {
				width: 24px;
				height: 24px;
				left: 12px;
				
				&.launcher {
					background-image: url('../../../assets/img/integration/logo-taptalk-side-menu.svg');
				}

				&.telegram {
					background-image: url('../../../assets/img/integration/logo-telegram-side-menu.svg');
				}

				&.whatsapp {
					background-image: url('../../../assets/img/integration/logo-whatsapp-side-menu.svg');
				}

				&.twitter {
					background-image: url('../../../assets/img/integration/logo-twitter-side-menu.svg');
				}

				&.line {
					background-image: url('../../../assets/img/integration/logo-line-side-menu.svg');
				}

				&.fb {
					background-image: url('../../../assets/img/integration/logo-facebook-side-menu.svg');
				}

				&.whatsappBusiness {
					background-image: url('../../../assets/img/integration/logo-whatsapp-business.svg');
				}
			}
		}
	}
}
