.error-or-info-comp {
    font-size: 12px;
    letter-spacing: -0.4px;
    position: relative;
    padding: 0 0 0 16px;
    margin: 8px 0 0;
    color: rgba(25, 25, 25, 0.8);

    svg {
        position: absolute;
        top: 3px;
        left: 0;
    }
}