.home-welcome-message {
  margin: 0;
  letter-spacing: -0.6px;
  font-size: 24px;
  color: #191919;
}

.home-info-wrapper {
  border-top: solid 1px rgba(25, 25, 25, 0.1);
  margin-top: 24px;
  padding-top: 28px;
  display: flex;

  .home-info-content {
    width: 300px;
    display: inline-block;
    max-width: 33%;

    .home-info-image {
      padding: 6px;
      border-radius: 8px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.05);
      display: inline-block;
      vertical-align: top;
    }

    .home-info-detail {
      width: calc(100% - 48px);
      display: inline-block;
      padding-left: 8px;
      position: relative;

      .home-info-detail-title {
        font-size: 10px;
        color: rgba(25, 25, 25, 0.6);
        margin: 0;
        letter-spacing: 1.5px;
        line-height: 15px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .home-info-detail-value {
        letter-spacing: -0.5px;
        font-size: 16px;
        margin: 0;
        line-height: 18px;
        white-space: nowrap;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 24px;
        position: relative;
        top: -2px;
      }

      .home-topup-button {
        padding: 2px 10px 2px 5px;
        line-height: 15px;
        svg {
          height: 12px;
          font-size: 12px;
          margin: 0 2px;
        }

        .button-text-wrapper {
          font-size: 12px;
        }
      }

      .loading-wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        color: rgba(25, 25, 25, 0.4);

        .spinner-wrapper {
          width: 28px;
          height: 28px;
          position: relative;
          top: 0;

          .lds-ring {
            div {
              border-color: #a9a9a9 transparent transparent transparent;
            }
          }
        }
      }
    }
  }
}

.setup-room-list-modal {
  background: #4e4e4e;

  .lds-ring div {
    margin: 0;
  }

  .modal-content {
    width: unset;
    min-width: 268px;
    display: inline-block;
    border-radius: 8px;
    padding: 32px 16px;
    text-align: center;
    position: relative;

    .setup-main-content {
      .setup-image-content {
        height: 64px;
        width: 64px;
        display: inline-block;
        position: relative;
        border-radius: 50%;
        margin-bottom: 12px;

        .lds-ring {
          position: absolute;
          left: 0;
          top: 0;
          height: 64px;
          width: 64px;

          div {
            height: 64px;
            width: 64px;
            border: 8px solid;
            border-color: #ff7d00 transparent transparent transparent;
          }
        }

        &.setup-success {
          border: 12px solid #19C700;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .setup-fail {
        color: #ff3f57;
      }

      b {
        font-size: 20px;
        letter-spacing: -0.6px;
        color: #ff7d00;
      }

      p {
        font-size: 16px;
        letter-spacing: -0.3px;
        margin: 8px 0 0;

        svg {
          font-size: 20px;
          margin-right: 7px;
          font-size: 25px;
          position: relative;
          top: -1px;
        }

        &.retry-setup {
          b {
            color: #ff7d00;
            transform: ease-in-out 0.1s;
            cursor: pointer;
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 16px;
          }

          &:hover {
            color: var(--main-darker-orange);
          }
        }
      }
    }
  }
}

.modal-dialog.setup-room-list-modal {
  width: 100vw !important;
}

.modal-agreement {
  .modal-content {
    width: 476px;
    border-radius: 8px;
    padding: 32px;

    .modal-body {
      padding: 0;
    }

    .modal-agreement-title {
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #191919;
      margin-bottom: 4px;

      svg {
        font-size: 22px;
        margin-right: 4px;
        position: relative;
        top: -2px;
      }
    }

    p {
      color: rgba(25, 25, 25, 0.6);
      font-size: 14px;
      letter-spacing: -0.2px;
      line-height: 21px;
    }

    .custom-checkbox {
      background: rgba(25, 25, 25, 0.05);
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 20px;

      label {
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 21px;
        margin: 0;
      }
    }
  }
}