.modal-reset-password-email-sent {
  img {
    width: 30px;
    position: relative;
    top: -2px;
  }

  .modal-content {
    padding: 30px 40px;
    border-radius: 8px;
    width: 540px;
  }

  .modal-body {
    padding: 0;

    p {
      font-size: 16px;
      font-weight: normal;
      color: #191919;
      letter-spacing: -0.3px;
      text-align: center;
    }

    svg {
      font-size: 26px;
      position: relative;
      top: -2px;
    }

    p:nth-child(1) {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -0.4px;
      color: #52d540;
      
      svg {
        margin-right: 8px;
      }
    }
  }

  .modal-footer {
    padding: 0 0 10px;
    border: none;

    button {
      // padding: 10px 0 12px;
      width: 100%;
      position: relative;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        margin-right: 4px;
        font-size: 20px;
      }

      b {
        font-size: 16px;
      }
    }
  }

  .lds-ring div {
    border-color: #ffffff transparent transparent transparent;
  }
}

.loading-sent-email-button {
  width: 100%;
  margin-top: 10px;
  padding: 10px 0 12px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border: solid 1px #ff7e00;
  background: linear-gradient(to bottom, #ff9833, #ff7e00 100%);
  cursor: pointer;
  position: relative;
  height: 48px;
}

.loading-sent-email-button .lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading-sent-email-button .lds-ring div {
  border-color: #ffffff transparent transparent transparent;
  top: 0;
  left: 0;
  margin: 0;
}

.loading-sent-email-button:hover {
  background: linear-gradient(to bottom, #ff9833, #ef7600 100%);
}

.loading-sent-email-button:disabled {
  /* background: linear-gradient(to bottom, #cecece, #9b9b9b 100%); */
  cursor: not-allowed;
  border: solid 1px #cecece;
}

@media screen and (max-width: 1025px) {
  .modal-reset-password-email-sent {
    img {
      width: 20px;
      position: relative;
      top: -3px;
    }
    .modal-content {
      padding: 32px 24px;
      border-radius: 12px;
      width: 476px;
    }

    .modal-body {
      p {
        font-size: 14px;
        letter-spacing: -0.2px;
      }
  
      svg {
        font-size: 20px;
        position: relative;
        top: -3px;
      }
  
      p:nth-child(1) {
        font-size: 20px;
        letter-spacing: -0.6px;
      }
    }
    // .modal-footer {
    //   button {
    //     svg {
    //       font-size: 14px;
    //     }
  
    //     b {
    //       font-size: 14px;
    //     }
    //   }
    // }
  }
}

@media screen and (max-width: 480px) {
  .modal-reset-password-email-sent {
    .modal-content {
      width: 343px;
    }
  }
}