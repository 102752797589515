.copy-box-wrapper {
    // border: 1px solid rgba(25, 25, 25, 0.1);
    display: block;
    position: relative;
    // background: #fff;
    border-radius: 8px;

    input, textarea {
        border: 0;
        white-space: nowrap;
        background: #fff;
        padding: 8px 52px 8px 20px;
        color: #191919;
        border-radius: 8px;
        font-size: 16px;
        letter-spacing: -0.3px;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        resize: none;
        background: 1px solid rgba(25, 25, 25, 0.1);
    }

    textarea {
        word-wrap: break-word;
        white-space: unset !important;
        overflow: auto;
        height: 100px;
    }

    svg {
        position: absolute;
        top: 12px;
        right: 20px;
        color: var(--main-dark-orange); 
        cursor: pointer;

        &:hover {
            color: var(--main-darker-orange);
        }
    }
}