.reset-password-image img {
    width: 85%;
}

.div50 {
    display: inline-block;
    width: 50%;
    position: relative;
    vertical-align: top;
}

.div50 div {
    letter-spacing: -0.2px;
    color: #191919;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    position: relative;
    padding-left: 22px;
}

.div50 div svg {
    position: absolute;
    top:50%;
    left:0;
    transform: translate(0, -50%);
}

.disabled-retype-password:disabled {
    color: rgba(25, 25, 25, 0.2) !important;
    border: none !important;
    background: rgba(25, 25, 25, 0.1) !important;
    cursor: not-allowed;
}

.input-wrapper .popup-input-password-wrapper {
    position: relative;
    /* border: solid 1px #dcdcdc; */
    border-radius: 8px;
    // width: 310px;
}

.input-wrapper .popup-input-password-wrapper input {
    padding: 12px 50px 14px 15px;
    width: 100%;
    display: inline-block;
    border: none;
    border-radius: 8px;
    letter-spacing: -0.2px;
    font-size: 16px;
    color: #191919;
    border: solid 1px #dcdcdc;
}

.input-wrapper .popup-input-password-wrapper .peek-password {
    position: absolute;
    width: 50px;
    color: #191919;
    text-align: center;
    top: 12px;
    right: 1px;
    height: 41px;
    line-height: 40px;
    font-size: 20px;
    /* border-left: solid 1px #dcdcdc; */
    cursor: pointer;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    // background: #ffffff;
}

.input-wrapper .popup-input-password-wrapper .peek-password.active-eye {
  color: #ff7d00;
}

.input-wrapper .popup-input-password-wrapper .peek-password.disable-seek-password {
    cursor: not-allowed;
}

// .input-wrapper .popup-input-password-wrapper input:focus + .peek-password {
//     color: #ff7d00;
// }

.input-wrapper .popup-input-password-wrapper input:focus {
    border: solid 1px #ff7d00;
}


@media screen and (max-width: 415px) {
    .password-strength .div50 {
        width: 100%;
    }
}

.popup-modal-change-password {
    text-align: center;
}

.popup-modal-change-password .popup-modal-change-password-button {
  margin: 0;
}

.popup-modal-change-password .modal-content {
  width: 540px;
}

.popup-modal-change-password .modal-body {
    border-radius: 8px;
}

.popup-modal-change-password .modal-body {
    padding: 35px 48px 32px;
}

.popup-modal-change-password-title {
    font-size: 24px;
    letter-spacing: -0.6px;
    color: #52d540;
    margin: 0;
}

.popup-modal-change-password-title img {
    position: relative;
    top: -2px;
    margin-right: 8px;
}

.popup-modal-change-password-description {
    font-size: 16px;
    letter-spacing: -0.3px;
    margin: 8px 0 24px;
}



// verify reset password
.outer-verify-reset-password {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: linear-gradient(135deg, #FFAB3A 0%, #FF7E00 100%);
  overflow: auto;

  .background-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    bottom: 0;
    background: url('../../../assets/img/auth/illustration-auth.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;

    .loading-wrapper {
      width: 160px;
      height: 144px;
      padding: 40px 48px;
      border-radius: 6px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
      background-color: #ffffff;
  
      .auth-lds-ring {
        display: block;
        position: relative;
        width: 64px;
        height: 64px;
        
        div {
          width: 64px;
          height: 64px;
          border-width: 8px;
          top: -3px;
          right: -5px;
        }
      }
    }
  
    .failed-wrapper {
      width: 540px;
    //   height: 222px;
      padding: 32px 48px;
      border-radius: 6px;
      box-shadow: 0 2px 20px 0 rgba(25, 25, 25, 0.18);
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .logo {
        width: 154px;
        margin-bottom: 24px;
      }
  
      .message-wrapper {
        display: flex;
        align-items: center;
  
        b {
          font-size: 24px;
          color: #ff3f57;
          margin-bottom: 8px;
          text-align: center;
        }
      }

      p {
        &.font-root-medium {
          text-align: center;
          color: rgba(25, 25, 25, 0.8)
        }
      }
  
      button {
        &.forgot-password {
          width: 444px;
          height: 48px;
          margin: 0;
          padding: 12px 0;
          border-radius: 8px;
          box-shadow: 0 0 8px 0 rgba(255, 126, 0, 0.24);
          border: solid 1px #ff7e00;
          background-image: linear-gradient(to bottom, #ff9833, #ff7d00);
          display: flex;
          justify-content: center;
          align-items: center;
  
          &:hover {
            background: linear-gradient(to bottom, #ff9833, #ef7600 100%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .popup-modal-change-password .modal-content {
    width: 476px;
    border-radius: 12px;
  }
  .popup-modal-change-password .modal-body {
    padding: 32px 24px;
    .popup-modal-change-password-title {
      font-size: 20px;
      line-height: 24px;
      img {
        width: 20px;
        top: -3px;
      }
    }
    .popup-modal-change-password-description {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.2px;
    }
  }
  .outer-verify-reset-password {
    .background-container {
      .failed-wrapper {
          width: 476px !important;
          border-radius: 12px;
          padding: 32px 24px !important;
          .message-wrapper {
            font-size: 20px;
          }
          p {
            font-size: 14px;
          }
      }
    }
  }
  .outer-verify-reset-password .background-container .failed-wrapper button.forgot-password {
    width: 100% !important;
  }
}

@media screen and (max-width: 480px) {
  .popup-modal-change-password .modal-content {
    width: 343px;
  }
  .outer-verify-reset-password {
    .background-container {
      .failed-wrapper {
          width: 343px !important;
          border-radius: 12px;
          padding: 32px 24px !important;
      }
    }
  }
  .outer-verify-reset-password .background-container .failed-wrapper button.forgot-password {
    width: 100% !important;
  }
}
