#verify-email-wrapper {
    width: 540px;
    padding: 32px 48px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.18);
    text-align: center;
    box-sizing: border-box;
}

#verify-email-wrapper img {
    width: 154px;
    display: inline-block;
}

#verify-email-wrapper p {
    font-size: 16px;
    color: #191919;
    letter-spacing: -0.3px;
    margin: 0;
}

#verify-email-wrapper .process-loading {
    color: #9A9A9A;
    font-size: 25px;
    font-weight: bold;
    margin: 25px 0 10px;
}

#verify-email-wrapper .verification-failed {
    color: #F93D60;
    font-size: 25px;
    font-weight: bold;
    margin: 25px 0 10px;
}

#verify-email-wrapper .verification-failed img {
    width: 30px;
    position: relative;
    top: -2px;
    margin: 0 5px 0 0;
}

#verify-email-wrapper .verification-failed p {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-top: 7px;
}

#verify-email-wrapper .verification-failed button {
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
}

#verify-email-wrapper .verification-success {
    color: #52d540;
    font-size: 25px;
    font-weight: bold;
    margin: 25px 0 10px;
}

.verification-success-label {
    margin-bottom: 4px;
}

#verify-email-wrapper .verification-success img {
    width: 30px;
    position: relative;
    top: -2px;
    margin: 0 5px 0 0;
}

#verify-email-wrapper .verification-success p {
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
}

#verify-email-wrapper .verification-success button {
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
}

#verify-email-wrapper .process-loading .lds-ring {
    width: 30px;
    height: 30px;
    top: 2px;
    left: 0;
    margin-right: 8px;
}

#verify-email-wrapper .process-loading .lds-ring div {
    width: 26px;
    height: 26px;
    border: solid 3px;
}

#verify-email-wrapper .process-loading .lds-ring div {
    border-color: #9A9A9A transparent transparent transparent;
}

@media screen and (max-width: 600px) {
    #verify-email-wrapper {
        width: 100%;
        border-radius: 0;
        padding: 40px 28px;
    }

    #verify-email-wrapper .verify-email-status {
        font-size: 20px !important;
    }

    #verify-email-wrapper .verify-email-status svg {
        top: -2px !important;
        width: 21px;
    }

    #verify-email-wrapper .login-button {
        padding: 11px 0;
        font-size: 15px;
    }
}

#verify-email-wrapper  .resend-email-wrapper {
    letter-spacing: -0.2px;
    text-align: center;
    color: #191919;
    margin-top: 15px;
}

#verify-email-wrapper  .resend-email-wrapper .resend-button {
    font-size: 16px;
    color: var(--main-dark-orange);
    font-weight: bold;
    display: block;
    margin-top: 10px;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

#verify-email-wrapper  .resend-email-wrapper .resend-button:hover {
    transform: scale(0.95);
}

#verify-email-wrapper  .resend-email-wrapper .resend-button svg {
    position: relative;
    top: -2px;
    font-size: 20px;
    margin-left: 1px;
}

#verify-email-wrapper  .resend-email-wrapper .timer-resend-button {
    font-size: 16px;
    color: #9b9b9b;
    font-weight: bold;
    display: block;
    margin-top: 10px;
}

@media screen and (max-width: 1025px) {
    #verify-email-wrapper {
        width: 476px;
        padding: 32px 24px;
        border-radius: 12px;
    }
    .verification-success-label b {
            font-size: 20px;
    }
    .verification-success-label img {
        width: 20px !important;
        /* top: -3px !important; */
    }
    #verify-email-wrapper p {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.2px;
    }
    #verify-email-wrapper .process-loading {
        font-size: 20px;
        margin: 25px 0 10px;
    }
    #verify-email-wrapper .process-loading b {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
    }
    #verify-email-wrapper .process-loading .lds-ring {
        width: 20px;
        height: 20px;
        top: -3px;
        margin-right: 15px;
    }
    #verify-email-wrapper .process-loading .lds-ring div {
        width: 20px;
        height: 20px;
        /* border: solid 2px; */
    }
}

@media screen and (max-width: 480px) {
    #verify-email-wrapper {
        width: 343px;
    }
}