.custom-switch-wrapper {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 42px;
  }
  
  .custom-switch-wrapper input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .custom-switch-wrapper .custom-switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .custom-switch-wrapper .custom-switch:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    left: 8px;
    bottom: 8px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    box-shadow: 0px 2px 4px grey;
  }
  
  input:checked + .custom-switch {
    background-color: #19C300;
  }
  
  input:focus + .custom-switch {
    box-shadow: 0 0 1px #79c028;
  }
  
  input:checked + .custom-switch:before {
    transform: translateX(29px);
  }
  
  /* Rounded custom-switchs */
  .custom-switch.round {
    border-radius: 34px;
    margin: 0 !important;
  }
  
  .custom-switch.round:before {
    border-radius: 50%;
  }