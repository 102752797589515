@font-face {
  font-family: "PTRootUI";
  src: url("font/PTRootUI.ttf");
}

@font-face {
  font-family: "PTRootBold";
  src: url("font/PT Root UI_Bold.ttf");
}

@font-face {
  font-family: "PTRootMedium";
  src: url("font/PT Root UI_Medium.ttf");
}

:root {
  --main-light-red: #ff7e8e;
  --main-dark-red: #ff3f57;
  --main-dark-orange: #ff7e00;
  --main-darker-red: #e83b51;
  --main-darker-orange: #e87200;
  --main-light-orange: #fdcb56;
  --main-light-grey: #cecece;
  --main-dark-grey: #9b9b9b;
  --main-darker-orange: #e87200;
  --watermelon: #ff3f57;
  --main-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.18),
    0px 0px 21px 0px rgba(0, 0, 0, 0.1);
  /* --button-vertical-padding-large: 13px 0 14px; */
}

body {
  margin: 0;
  padding: 0;
  font-family: PTRootUI !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
h1,
h2,
h3 {
  font-family: PTRootBold !important;
  font-weight: 500 !important;
  margin: 0;
}

.font-root-medium {
  font-family: PTRootMedium !important;
}

.grey-font {
  color: rgba(25, 25, 25, 0.6);
}

.cursor-default {
  cursor: default !important;
}

input,
select,
textarea,
button {
  font-family: PTRootUI;
  outline: unset !important;
}

.disabled-input {
  background: rgba(25, 25, 25, 0.1);
  color: rgba(25, 25, 25, 0.4);
  font-size: 16px;
  letter-spacing: -0.3px;
  border-radius: 8px;
  vertical-align: top;
  display: inline-block;
  height: 48px;
  padding: 12px 16px;
}

button {
  transition: ease-in-out 0.3s;
}

a {
  text-decoration: none !important;
}

a.orange {
  color: #ff7e00;
  text-decoration: underline !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

mono {
  font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,Courier,monospace !important;
}

.input-warning {
  color: red;
  margin: 0;
  font-size: 13px;
  padding-bottom: 16px;
}

.no-background-and-border-button {
  border: 0;
  background: 0;
  padding: 0;
}

.no-fill-button {
  border: solid 1px var(--main-dark-orange) !important;
  color: var(--main-dark-orange) !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button:hover {
  background-color: rgba(255, 126, 0, 0.05);
}

.no-fill-button:active {
  background-color: rgba(255, 126, 0, 0.18);
}

.no-fill-button-red {
  border: solid 1px #ff3f57 !important;
  color: #ff3f57 !important;
  background: transparent;
  outline: none;
  box-shadow: none !important;
  border-radius: 8px;
  font-weight: bold;
}

.no-fill-button-red:hover {
  background-color: rgba(255, 126, 0, 0.05);
}

.no-fill-button-red:active {
  background-color: rgba(255, 126, 0, 0.18);
}

.orange-button {
  background: linear-gradient(#ff9833, var(--main-dark-orange));
  color: white !important;
  border: solid 1px var(--main-dark-orange) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;
}

.orange-button:hover {
  background: var(--main-dark-orange);
  box-shadow: 0 12px 12px 0 rgba(255, 126, 0, 0.2),
    0 0 12px 0 rgba(255, 126, 0, 0.1);
}

.orange-button:active {
  background: var(--main-darker-orange);
  box-shadow: 0 6px 6px 0 rgba(255, 126, 0, 0.2),
    0 0 6px 0 rgba(255, 126, 0, 0.1);
}

.light-orange-button {
  background: #FFF2E5;
  color: #ff7d00 !important;
  border: solid 1px #FFF2E5 !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;
  
  &:hover {
    background: #ff7d00;
    color: #ffffff !important;
    box-shadow: 0 12px 12px 0 rgba(255, 126, 0, 0.2),
      0 0 12px 0 rgba(255, 126, 0, 0.1);
  }

  svg {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.orange-button-disabled {
  background: linear-gradient(#ff9833, var(--main-dark-orange));
  color: white !important;
  border: solid 1px var(--main-dark-orange) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;
}

.blue-button {
  background-color: rgba(4, 75, 132, 1);
  color: #ffffff;
  border-radius: 8px;
  margin-top: 8px;
  font-size: 14px;

  &:hover {
    background-color: rgba(4, 75, 132, .9);
  }
}

.red-button {
  background: #ff3f57;
  color: white !important;
  border: solid 1px var(--main-dark-red) !important;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;
}

.red-button.solid {
  background: var(--main-dark-red);
}

.red-button:hover {
  /* box-shadow: 0 12px 12px 0 rgba(255, 63, 87, 0.2),
    0 0 12px 0 rgba(255, 63, 87, 0.1); */
  background: #e83b51;
}

.red-button-no-fill {
  color: #FF3F57;
  background: transparent;
  outline: none;
  /* box-shadow: none !important; */
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;

  &:hover {
    background: #FAEEEE;
  }
}

.green-button {
  background: #19c700;
  border: solid 1px #19c700 !important;
  color: #ffffff !important;
  outline: none;
  border-radius: 8px;
  transition: east-in-out 0.2s;
  font-weight: bold;
}

.green-button:hover {
  background: #19B503;
  color: #ffffff !important;
}

.yellow-button {
  background: #ffb438;
  color: #ffffff !important;
}

.yellow-button:hover {
  background: #f8ad30;
  color: #ffffff !important;
}

.red-button:active {
  background: #e83b51;
}

.action-button {
  display: flex;
  align-items: center;
  padding: 4px;
  margin: 0 6px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: var(--main-dark-orange) !important;
  background: transparent;
  border: none;
  font-weight: 600;
}

.action-button * {
  margin: 0 4px;
}

.no-fill-button,
.orange-button,
.red-button {
  font-family: PTRootBold;
}

.no-fill-button.wide-button,
.orange-button.wide-button,
.red-button.wide-button {
  min-width: 300px !important;
}

.action-button.del {
  color: var(--watermelon) !important;
}

button[disabled] {
  color: rgba(25, 25, 25, 0.2) !important;
  border: solid 1px rgba(25, 25, 25, 0.1) !important;
  background: rgba(25, 25, 25, 0.1) !important;
  cursor: not-allowed;
  box-shadow: none !important;
}

.orange-text {
  color: #ff7e00;
  cursor: pointer;

  &:hover {
    color: var(--main-darker-orange) !important;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.no-result-found {
  color: rgba(25, 25, 25, 0.4);
}

.custom-tapTalk-card {
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 2px 0 rgba(0, 0, 0, 0.18);
  background-color: #ffffff;
}



.taptalk-form label {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #191919;
  display: block;
}

.taptalk-form input[type="text"] {
  padding: 10px 15px 12px;
  border-radius: 8px;
  border: solid 1px #dcdcdc;
  margin: 10px 0 15px;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #191919;
  position: relative;
}


/* .recharts-layer path {
	fill: red;
} */
/* custom chart */

.input-file-wrapper input {
  display: none;
}

.input-file-wrapper label {
  padding: 12px 35px;
  display: inline-block !important;
  margin: 10px 0 24px !important;
}

.input-file-wrapper label svg {
  position: relative;
  top: -1px;
  margin-right: 4px;
  cursor: pointer;
}

.sort-by-dropdown .dropdown .dropdown-toggle {
  background: transparent !important;
  color: var(--main-dark-orange) !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-weight: bold;
}

.sort-by-dropdown .dropdown .dropdown-toggle:hover {
  color: var(--main-darker-orange) !important;
}

.sort-by-dropdown .dropdown .dropdown-toggle::after {
  position: relative;
  top: 1px;
  margin-left: 8px;
}

.go-back-wrapper a {
  color: var(--main-dark-orange) !important;
  display: block;
  padding-bottom: 15px;
}

.go-back-wrapper a svg {
  position: relative;
  top: -1px;
}

.box-extra-info {
  border-radius: 8px;
  padding: 11px 16px 11px 52px;
  font-size: 16px;
  color: #191919;
  display: block;
  position: relative;
}

.box-extra-info.info-blue {
  background: #dcebff;
  color: #044b84;
}

.box-extra-info.info-brown {
  background: #fdd984;
}

.box-extra-info svg {
  font-size: 28px;
  position: absolute;
  left: 16px;
  top: 9px;
}

.box-extra-info p {
  margin: 0;
}

.manage-project-tool-wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-top: 0;
  padding: 0;
  border: none;
  height: 46px;
  margin-top: 0;
  padding: 0;
}

.manage-project-tool-wrapper #button-box-wrapper {
  text-align: right;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}

.manage-project-tool-wrapper #button-box-wrapper button {
  margin-left: 10px;
  padding: 10px 30px;
}

.button-padding-with-icon {
  padding: 8px 32px 8px 24px;
}

.button-padding-without-icon {
  padding: 8px 24px;
}

.user-avatar-name {
  text-transform: uppercase;
}

.button-flex {
  margin-top: 0 !important;
}

.form-title-wrapper {
  padding-bottom: 16px;
  border-bottom: solid 1px rgba(25, 25, 25, 0.1);
  color: #191919;
  font-size: 20px;
  letter-spacing: -0.6px;
}

.alert-component {
  border-radius: 8px;
  position: relative;
  padding: 12px 48px;
  letter-spacing: -0.5px;
  font-size: 16px;
  display: inline-block;
  vertical-align: top;

  svg {
    position: absolute;
    font-size: 24px;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  img {
    position: absolute;
    font-size: 24px;
    left: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &.alert-blue {
    color: #044b84;
    background: #C5DFFE; 
    border: solid 1px #044b84;
  }
}

.step-container {
  .step-content {
    display: inline-block;

    .step-number {
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      font-size: 12px;
      letter-spacing: -0.4px;
      color: #ffffff;
      background: rgba(25, 25, 25, 0.4);
      border-radius: 50%;
      display: inline-block;
    }

    .step-wording {
      font-size: 14px;
      color: #19191966;
      letter-spacing: -0.4px;
      display: inline-block;
      position: relative;
      top: 1px;
      margin-left: 6px;
    }

    &.orange-step {
      .step-number {
        background: #ff7d00;
      }

      .step-wording {
        color: #ff7d00;
      }
    }
  }

  .step-line {
    height: 2px;
    width: 20px;
    display: inline-block;
    margin: 0 12px;
    background: rgba(25, 25, 25, 0.4);
    position: relative;
    top: -2px;

    &.step-line-orange {
      background: #ff7d00;
    }
  }
}

.tooltip-inner {
  font-size: 14px;
  letter-spacing: -0.2px;
  text-align: left !important;
  line-height: 20px;
  padding: 6px 12px !important;
}

.yellow-text {
  color: #F4C12A !important;
}

.red-text {
  color: #FF3F57 !important;
}

.green-text {
  color: #19C700 !important;
}

.border-red {
  border: solid 1px #ff3f57 !important;
}

.alert-box {
  position: relative;
  padding: 8px 16px 8px 42px;
  font-size: 16px;
  letter-spacing: -0.5px;
  border-radius: 8px;

  svg {
    font-size: 20px;
    position: absolute;
    left: 16px;
    top: 10px;
  }
}

@import "buttonStyle.scss";
@import "customDropdown.scss";
@import "formContent.scss";
@import "customDatePicker.scss";
@import "customTable.scss";
@import "customRadio.scss";
@import "customCheckbox.scss";
@import "customCheckbox.scss";
@import "customSwitch.scss";
@import "toastStyle.scss";
@import "modalStyle.scss";
@import "ldsStyle.scss";
