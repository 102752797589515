#verification-wrapper {
    width: 540px;
    padding: 48px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.18);
    text-align: center;
}

#verification-wrapper img {
    width: 154px;
    display: inline-block;
}

@media screen and (max-width: 600px) {
    #verification-wrapper {
        width: 100%;
        border-radius: 0;
        padding: 40px 28px;
    }

    #verification-wrapper .verification-status {
        font-size: 20px !important;
    }

    #verification-wrapper .verification-status svg {
        top: -2px !important;
        width: 21px;
    }

    #verification-wrapper .login-button {
        padding: 11px 0;
        font-size: 15px;
    }
}

#verification-wrapper  .resend-email-wrapper {
    letter-spacing: -0.2px;
    text-align: center;
    color: #191919;
    margin-top: 15px;
}

#verification-wrapper  .resend-email-wrapper .resend-button {
    font-size: 16px;
    color: var(--main-dark-orange);
    font-weight: bold;
    display: block;
    margin-top: 10px;
    transition: ease-in-out 0.1s;
    cursor: pointer;
}

#verification-wrapper  .resend-email-wrapper .resend-button:hover {
    transform: scale(0.95);
}

#verification-wrapper  .resend-email-wrapper .resend-button svg {
    position: relative;
    top: -2px;
    font-size: 20px;
    margin-left: 1px;
}

#verification-wrapper  .resend-email-wrapper .timer-resend-button {
    font-size: 16px;
    color: #9b9b9b;
    font-weight: bold;
    display: block;
    margin-top: 10px;
}